import {createRouter, createWebHashHistory} from 'vue-router'
import store from "@/store";

// GPT相关的子路由
const GPT_CHILDREN_PARENT_PATH = ''
const gptChildrenRoutes = [
    {
        path: '',
        name: 'default',
        component: () => import('@/views/gpt/GptRightView')
    },
    {
        path: 'gpt',
        name: 'gpt',
        component: () => import('@/views/gpt/GptRightView')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'channel',
        name: 'channel',
        component: () => import('@/views/gpt/GptRightView')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'orders',
        name: 'gptOrders',
        component: () => import('@/views/gpt/admin/GptOrderList')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'employee',
        name: 'gptEmployee',
        component: () => import('@/views/gpt/admin/GptEmployee')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'gad',
        name: 'gptAdminDashboard',
        component: () => import('@/views/gpt/admin/GptAdminDashboard')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'ranking',
        name: 'gptAdminRanking',
        component: () => import('@/views/gpt/admin/GptAdminRanking')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'users',
        name: 'gptAdminUsers',
        component: () => import('@/views/gpt/admin/GptAdminUser')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'user/details/:id',
        name: 'gptUserDetails',
        component: () => import('@/views/gpt/admin/GptUserDetails')
    },
    {
        path: GPT_CHILDREN_PARENT_PATH + 'agency',
        name: 'gptAdminAgency',
        component: () => import('@/views/gpt/admin/GptAdminAgency')
    },
]

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/gpt/GptHomeIndex')
    },
    {
        path: '/aboutgpt',
        name: 'aboutgpt',
        component: () => import('@/views/gpt/GptAbout')
    },
    {
        path: '/productgpt',
        name: 'productgpt',
        component: () => import('@/views/gpt/GptProduct')
    },
    {
        path: '/servicegpt',
        name: 'servicegpt',
        component: () => import('@/views/gpt/GptService')
    },
    {
        path: '/useragreement',
        name: 'gptUserAgreement',
        component: () => import('@/views/gpt/GptUserAgreement')
    },
    {
        path: '/privacypolicy',
        name: 'gptPrivacyPolicy',
        component: () => import('@/views/gpt/GptPrivacyPolicy')
    },
    {
        path: '/wxAuth',
        name: 'wxAuth',
        component: () => import('@/views/gpt/WxAuthOkView')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/NewsView'),
    },
    {
        path: '/log',
        name: 'log',
        component: () => import('@/views/log/LogView')
    },
    {
        path: '/insider/gm',
        name: 'groupMail',
        component: () => import('@/views/GroupMail')
    },
    {
        path: '/insider/chat',
        name: 'chat',
        component: () => import('@/views/ChatView')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/LoginView')
    },
    {
        path: '/gpt',
        name: 'gptHome',
        component: () => import('@/views/gpt/GptIndex'),
        children: gptChildrenRoutes
    },
    {
        path: '/personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/apps/PersonalCenter')
    },
    {
        path: '/insider/setting',
        name: 'setting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/setting/SettingView.vue')
        component: () => import('@/views/setting/SettingView')
    },
    {
        path: '/signUp',
        name: 'signUp',
        component: () => import('@/views/login/SignUpView')
    },
    {
        path: '/restPwd',
        name: 'resetPwd',
        component: () => import('@/views/login/ResetPwd')
    },
    {
        path: '/insider/erp',
        name: 'erp',
        component: () => import('@/views/erp/ErpView')
    },
    {
        path: '/insider/erpIndex',
        name: 'erpIndex',
        component: () => import('@/views/erp/ErpIndexView'),
        redirect: {name: 'threeErp'},
        children: [
            {
                path: '/threeErp',
                name: 'threeErp',
                component: () => import('@/views/erp/ThreeErpView')
            },
            {
                path: '/QPWWErp',
                name: 'qpErp',
                component: () => import('@/views/erp/QpwwErpView')
            },
            {
                path: '/DyErp',
                name: 'dyErp',
                component: () => import('@/views/erp/DyErpView')
            },
            {
                path: '/YxErp',
                name: 'yxErp',
                component: () => import('@/views/erp/YxErpView')
            }
        ]
    },
    {
        path: '/goods',
        name: 'goods',
        component: () => import('@/views/erp/GoodsView')
    },
    {
        path: '/goodList',
        name: 'goodList',
        component: () => import('@/views/erp/GoodListView')
    },
    {
        path: '/vinPage',
        name: 'vinPage',
        component: () => import('@/views/store/VinCodePage')
    },
    {
        path: '/insider/company',
        name: 'company',
        component: () => import('@/views/enterprise/CompanyView')
    },
    {
        path: '/companyInfo',
        name: 'companyInfo',
        component: () => import('@/views/enterprise/CompanyInfoView')
    },
    {
        path: '/insider/autoReplay',
        name: "autoReplay",
        component: () => import('@/views/erp/AutoReplayView')
    },
    {
        path: '/apps/routine-shop',
        name: 'routineShop',
        component: () => import('@/views/apps/RoutineShop')
    },
    {
        path: '/insider/apps/routine-shop-adv/:key',
        name: 'routineShopAdv',
        component: () => import('@/views/apps/routine/RoutineShopCustom')
    },
    {
        path: '/insider/apps/routine-shop-admin',
        name: 'routineShopAdmin',
        component: () => import('@/views/apps/routine/RoutineAdmin'),
        children: [
            {
                path: '/insider/apps/routine/plugins/excel',
                name: 'routineER',
                component: () => import('@/views/apps/RoutineShop')
            },
            {
                path: '/insider/apps/routine/admin',
                name: 'routineAdmin',
                component: () => import('@/views/CommonFrame')
            }
        ]
    },
    {
        path: '/insider/iframe',
        name: 'iframe',
        component: () => import('@/views/CommonFrame')
    },
    {
        path: '/iframe',
        name: 'iframeGuest',
        component: () => import('@/views/CommonFrame')
    },
    {
        path: '/vin-yd',
        name: 'yd',
        component: () => import('@/views/store/VinYD')
    },
    {
        path: '/authEnterprise',
        name: 'authEnterprise',
        component: () => import('@/views/enterprise/AuthEnterprise')
    },
    {
        path: '/bindPhone',
        name: 'bindPhoneView',
        component: () => import('@/views/enterprise/BindPhoneView')
    },
    {
        path: '/excelView',
        name: 'excelView',
        component: () => import('@/views/enterprise/ExcelView')
    },
    {
        path: '/enterpriseWxHome',
        name: 'enterpriseWxHome',
        component: () => import('@/views/gpt/EnterpriseWxHome')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach(function (to, from, next) {
    if (to.path.startsWith('/insider')) {
        // 会员可访问的页面，全部放到 /insider 路径下
        if (!store.getters.currentUid()) {
            return next('/login')
        }
    }

    if (to.path.startsWith('/vip')) {
        // 充值的也可以考虑全局控制
        // 跳转到充值页面
    }

    next()
})

export default router
