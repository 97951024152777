<template>
  <div v-if="!exchangeType" class="card-active m-auto ginkgoto-sns-bind">
    <div class="card-body d-none p-1">
      <div id="snsLoginFrame" :class="'my-2 m-auto text-center rounded border-secondary ' + (isOnline?'': 'img-gray')"
           :style="'width: 16rem; height: 16rem; overflow: hidden;background:url(' + this.loginBgImg + ') no-repeat center; background-size: cover;'">
        <div v-if="show.qrcodeSpinner.switch"
             class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <span class="spinner-grow spinner-grow m-3" role="status" aria-hidden="true"></span>
          二维码加载中...
        </div>
        <div id="rescan" v-if="show.rescan.switch" v-on:click="rescan"
             class="btn btn-secondary btn-icon h-100 w-100 text-center d-flex flex-column justify-content-center">
          <i class="fi-refresh m-3"></i><span class="small text-black-50">二维码已失效</span>
        </div>
        <iframe id="qrcode" v-if="show.qrcode.switch" style="height: 20rem; width: 20rem;" v-bind:src="qrcodeImageUrl">
        </iframe>
      </div>
      <div class="fs-sm text-warning fw-normal text-center py-1 mb-0">{{ scanMsg[scanStatus] }}</div>
    </div>
    <div class="card-footer d-none p-1 px-2 border-0">
      <input class="form-control form-control-light" type="text" placeholder="存档码" v-model="wxName">
      <div class="border-light text-center pt-4 pb-3">
        <button v-show="!powerBtnLoading" type="button" class="btn btn-primary btn-sm" style="width: 8.5rem"
                v-on:click="power">
          <i :class="powerBtnSts.icon + ' me-2'"></i>{{ powerBtnSts.text }}
        </button>
        <button v-show="powerBtnLoading" type="button" class="btn btn-secondary btn-sm" style="width: 8.5rem">
          <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
          登录中...
        </button>
        <button type="button" class="btn btn-light btn-sm ms-2" style="width: 8.5rem"
                data-bs-dismiss="modal" aria-label="Close">关闭
        </button>
      </div>
    </div>
    <div class="p-4">
      <p class="text-light">通知：</p>
      <p class="text-light">为了更好的服务用户，功能升级优化，微信登录暂时关闭，开放时间另行通知!</p>
    </div>
  </div>
  <div v-if="exchangeType" class="text-warning text-center">
    该版本不支持WChat，请联系组货宝客服开通！
  </div>
</template>
<script>
import {useStore} from 'vuex'

export default {
  props: {
    uid: {
      type: String,
      required: true
    },
    sns: {
      type: Object,
      required: false,
    },
    snsDesc: {
      type: Object,
      required: false
    }
  },
  // name: 'About',
  data: () => {
    return {
      wxName: 'gkt-wx-01',
      scanStatus: 9999,
      scanMsg: {
        9999: '等待登录',
        0: '登录成功',
        1: '已取消',
        2: '等待用户扫码',
        3: '已扫码，请在手机上点击确认',
        4: '已确认',
        5: '二维码已失效',
        'retry': '请扫码并在手机上确认，如无反应尝试点登录!'
      },
      toc: 20,
      tocTimer: -1,
      snsId: '',
      qrcodeImageUrl: 'javascript:;',
      loginBgImg: 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/wx_online.png',
      show: {
        qrcode: {id: 'qrcode', switch: false},
        rescan: {id: 'rescan', switch: false},
        qrcodeSpinner: {id: 'qrcodeSpinner', switch: false}
      },
      isOnline: false,
      powerBtnSts: {},
      powerBtnInfo: {
        started: {icon: 'fi-power', text: '退出', btn: 'btn-primary', status: true},
        stopped: {text: '登录', icon: 'fi-play', btn: 'btn-secondary', status: false}
      },
      powerBtnLoading: false,
      exchangeType: 0,
    }
  },
  watch: {
    powerBtnLoading() {
      if (this.powerBtnLoading) {
        setTimeout(() => {
          this.powerBtnLoading = false
          this.scanStatus = 'retry'
        }, 7700)
      }
    }
  },
  methods: {
    initMsgHandler(currentSnsId) {
      if ('function' === typeof window.ginkgoTo['pushMsgHandler']) {
        console.log('开启登录事件监听', currentSnsId)
        const that = this
        window.ginkgoTo['pushMsgHandler']('loginListener', msg => {
          that.snsLoginListener(msg, currentSnsId)
        })
      }
      // filePathElement.innerText = filePath
    },
    snsLoginListener(msg, currentSnsId) {
      try {
        const {objData, type} = msg
        if (objData) {
          const {qrcode, qrCodeStatus, status, snsId, snsType, wxName} = objData
          if (0x1E === type) {
            this.powerBtnLoading = true
            this.powerBtnSts = this.powerBtnInfo.stopped
            if (currentSnsId === (snsId + '') || 'WX_WEB' === snsType) {
              this.snsId = snsId // 微信暂时只能有一个
              console.log('登录监听消息接收:%s', (currentSnsId === snsId), objData)
              if (-2 === qrCodeStatus) {
                this.setView({
                  open: [this.show.rescan],
                  close: [this.show.qrcode, this.show.qrcodeSpinner]
                })
              } else if (2 === status && qrcode) {
                console.log("qrcode:", qrcode)
                this.qrcodeImageUrl = qrcode
                const that = this;
                // let toc = this.toc
                const tocTimer = setInterval(function () {
                  // this.toc = toc--
                  if (0 >= --that.toc) {
                    clearInterval(tocTimer)
                    that.toc = 20
                  }
                }, 1000)
                this.setView({
                  open: [this.show.qrcode],
                  close: [this.show.rescan, this.show.qrcodeSpinner]
                })
              } else {
                // that.toc =
                // console.log('ScanStatus:', status, qrcode)
              }
              this.scanStatus = status
            }
            this.isOnline = false
          }
          if (27 === type && (currentSnsId === (snsId + '') || wxName)) {
            // 这里只是对页面组件进行操作
            this.setView({
              open: [],
              close: [this.show.rescan, this.show.qrcodeSpinner, this.show.qrcode]
            })
            // loginBgImg是用户的头像，应该在登录成功后用统一的返回值来传递
            this.loginBgImg = window.ginkgoTo?.RES_SCHEME + snsType + '/' + snsId + '/' + snsId + '.jpg'
            this.scanStatus = 0
            // this.powerBtnLoading = false
            this.powerBtnSts = this.powerBtnInfo.started
            this.isOnline = true
          }
          if (0x2714 === type && currentSnsId === (snsId + '')) {
            // this.powerBtnLoading = false
            this.powerBtnSts = this.powerBtnInfo.stopped
            this.isOnline = false
          }
        }

      } catch (e) {
        console.error(e)
      }
    },
    setView(args) {
      const {open, close} = args
      // console.log('openClose:', args)
      for (const e in open) {
        // console.log("open: ", open[e].switch)
        open[e].switch = true
      }
      for (const e in close) {
        // console.log("close: ", e)
        close[e].switch = false
      }
    },
    rescan() {
      window.ginkgoTo.invoke('executeSnsApi', {
        uid: this.uid,
        method: 'rescan',
        snsType: 'WX_WEB',
        snsId: this.snsId, // 第一次扫码后返回
        args: {}
      })
      this.setView({
        open: [this.show.qrcodeSpinner],
        close: [this.show.rescan]
      })
      // this.show.qrcode = true
    },
    start() {
      // 将用户登录过的snsId记录到vuex的store里，这里是全局的
      console.log('SettingView is setup...', this.store.getters.fetchSns(this.snsId))
      if (!this.isOnline && this.gkt) { // 用gkt来判断是不是electron的终端
        this.initMsgHandler(this.snsId)
        // TODO: uid应该是登录后放到vuex里的，不应该写在这里，对接登录后修改
        this.gktExecuteSnsApi({
          uid: this.uid,
          method: 'login',
          snsType: 'WX_WEB',
          snsId: this.snsId,
          args: {wxName: this.wxName}
        })
      } else {
        // TODO：使用wss框架
      }
    },
    stop() {
      if (this.gkt) {
        this.gktExecuteSnsApi({uid: this.uid, method: 'logout', snsType: 'WX_WEB', snsId: this.snsId, args: {}})
      }
    },
    power() {
      this.powerBtnLoading = true
      if (this.isOnline) {
        this.stop()
      } else {
        this.start()
      }
      // setTimeout(() => {
      //   this.powerBtnLoading = false
      // }, 20000)
    }
  },
  setup() {
    // setup
    // 1. 检查是否是electron的应用程序，如果是，window里面会有ginkgoTo对象
    const gkt = window.ginkgoTo

    const store = useStore()

    /**
     * 调用SNS相关接口
     *
     * params = {
     *       uid: 'xxxxxxx', // 当前登录系统的用户id，原BIS或是YLL的用户
     *       method: 'login',    // SNS对应的方法名（参考puppet框架）
     *       snsType: 'OICQ',    // SNS的类型，参考MsgType
     *       snsId: '2551688459', // QQ号、微信号、公众号或是小程序等
     *       args: {}     // PUPPET方法需要的参数
     *     }
     * */
    function gktExecuteSnsApi(params) {
      const EXE_SNS_API = 'executeSnsApi'
      console.log('gktExecuteSnsApi:', params)
      if ('function' === typeof gkt['invoke']) {
        // window.ginkgoTo.invoke('executeSnsApi', {uid: 'xxxxxxx', method: 'login', snsType: 'OICQ', snsId: '2551688459', args: {}})
        gkt['invoke'](EXE_SNS_API, params)
      }
    }

    // console.log('SettingView is setup...', store.getters.snsList)
    return {
      gkt,
      store,
      gktExecuteSnsApi,
    }
  },
  created() {
    this.powerBtnSts = this.powerBtnInfo.stopped
    if (this.sns) {
      this.snsId = this.sns.snsId
      this.loginBgImg = this.sns.avatar
    }
    this.wxName = this.sns?.wxName || ('gkt-wx-' + new Date().getTime())
    const user = this.store.getters.fetchUser(this.uid)
    if (user?.exchangeType) {
      this.exchangeType = user.exchangeType.length
    }
    if (this.snsDesc) {
      // this.loginBgImg = this.snsDesc.icon
    }
  },
  mounted() {
    // console.log('SettingView is mounted...')
  }

}
</script>
<style>
.ginkgoto-sns-bind {
  width: 23rem;
  height: 30rem;
}
</style>
