<template>
  <div class="card-active m-auto ginkgoto-sns-bind">
    <div class="card-body p-1">
      <!--      <div id="snsLoginFrame" :class="'my-2 m-auto text-center rounded border-secondary ' + (isOnline?'': 'img-gray')"
                 :style="'width: 15rem; height: 15rem; overflow: hidden;background:url(' + this.loginBgImg + ') no-repeat center; background-size: cover;'">-->
      <div id="snsLoginFrame" :class="' m-auto text-center'" style="height: 255px">
        <el-image :class="(isOnline || !imgGray?'': 'img-gray')" :src="this.loginBgImg"
                  :style="'width: 15rem; height: 15rem; overflow: hidden; no-repeat center; background-size: cover;'"></el-image>
      </div>
    </div>
    <div class="card-footer p-1 px-2 border-0">
      <input type="hidden" v-model="snsId">
      <input class="form-control form-control-light" type="text" placeholder="公众号ID" v-model="gzhId">
      <input class="form-control form-control-light mt-2" type="text" placeholder="客服账号" v-model="customId">
      <div class="border-light text-center pt-4 pb-3">
        <button v-show="!powerBtnLoading" type="button" class="btn btn-outline-primary btn-sm" style="width: 8.5rem"
                v-on:click="login">
          <i :class="powerBtnSts.icon + ' me-2'"></i>{{ powerBtnSts.text }}
        </button>
        <button v-show="powerBtnLoading" type="button" class="btn btn-secondary btn-sm" style="width: 8.5rem">
          <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
          Loading...
        </button>
        <button type="button" class="btn btn-outline-light btn-sm ms-2" style="width: 8.5rem"
                data-bs-dismiss="modal" aria-label="Close">关闭
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GzhLogin",
  props: {
    uid: {
      type: String,
      required: true
    },
    sns: {
      type: Object,
      required: false,
    },
    snsDesc: {
      type: Object,
      required: false
    }
  },
  data: () => {
    return {
      snsId: '',
      gzhId: '',
      customId: '',
      isOnline: false,
      imgGray: false,
      loginBgImg: 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/tongxun/en_qq.png',
      powerBtnSts: {},
      powerBtnInfo: {
        started: {icon: 'fi-power', text: '退出', btn: 'btn-primary', status: true},
        stopped: {text: '登录', icon: 'fi-play', btn: 'btn-secondary', status: false}
      },
      powerBtnLoading: false
    }
  },
  methods: {
    start() {
      // 将用户登录过的snsId记录到vuex的store里，这里是全局的
      console.log(`Gzh is setup...${this.snsId} = ${(!this.isOnline)}`, this.$store.getters.fetchSns(this.snsId))
      if (!this.isOnline && this.$gkt) { // 用gkt来判断是不是electron的终端
        this.$gkt.executeSnsApi(this.uid, this.snsId, 'WX_GZH', 'login', {
          token: {
            kfId: this.customId, // 客服
            uid: this.uid,
            gzhId: this.gzhId
          }
        })
        // this.gktExecuteSnsApi({uid: this.uid, method: 'login', snsType: 'WX_GZH', snsId: this.snsId, args: {}})
      } else {
        // TODO：使用wss框架
      }
    },
    stop() {
      if (this.gkt) {
        this.$gkt.executeSnsApi(this.uid, this.snsId, 'WX_GZH', 'logout', {})
        // this.gktExecuteSnsApi({uid: this.uid, method: 'logout', snsType: 'WX_GZH', snsId: this.snsId, args: {}})
      }
    },
    login() {
      if (this.gzhId) {
        // this.powerBtnLoading = true
        if (this.isOnline) {
          this.stop()
        } else {
          this.start()
        }
      }
    },
  },
  mounted() {

  },
  created() {
    this.powerBtnSts = this.powerBtnInfo.stopped
    if (this.sns?.payload) {
      this.snsId = this.sns.snsId
      this.gzhId = this.sns.payload?.gzhId
      this.customId = this.sns.payload?.kf_wx

      this.loginBgImg = this.sns.avatar
      this.imgGray = true
    }
    if (this.snsDesc) {
      this.loginBgImg = this.snsDesc.icon
    }
    // console.log('this.snsDesc', this.snsDesc)
  },
}
</script>

<style scoped>
.ginkgoto-sns-bind {
  width: 23rem;
  height: 30rem;
}
</style>
