<template>
  <Transition name="modal">
    <div v-if="showFriend" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <label class="text-white">好友列表设置</label>
            <input class="m-2" type="text" style="width: 260px"
                   @input="searchFriendList($event)"
                   placeholder="关键字查询">
            <label class="text-white">
              <input class="form-check-input" type="checkbox" @change="checkAllFriend" v-model="checkedFriend">
              全选</label>
          </slot>
        </div>

        <div class="modal-body tab-content py-1">
          <slot name="body">
            <ul class="list-group" v-if="currentPageData.length > 0 ">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                  v-for="friendItem in currentPageData" :key="friendItem.id">
                <span>{{ friendItem?.payload?.name }}</span>
                <input class="form-check-input" type="checkbox" :id="friendItem.id" :value="friendItem.id"
                       v-model="friendIds">
              </li>
            </ul>
            <div class="text-center text-white" style="height: 400px" v-else><label>数据加载中，请稍后...</label></div>
            <div class="align-content-center mt-1 text-center">
              <input type="button" value="首页" style="width: 80px" @click="firstPage">
              <input class="m-lg-1" type="button" value="上一页" style="width: 80px" @click="prevPage">
              <input type="button" value="下一页" style="width: 80px" @click="nextPage">
              <input class="m-lg-1" type="button" style="width: 80px" value="尾页" @click="lastPage">
            </div>
          </slot>
        </div>

        <div class="modal-footer d-sm-flex justify-content-between pt-2">
          <slot name="footer">
            <button
                class="btn btn-secondary d-block  fixed-bottom-btn" style="width: 13rem"
                @click="closeModal">关闭
            </button>
            <button class="btn btn-primary d-block  fixed-bottom-btn" type="button" style="width: 13rem"
                    @click="saveAttentionFriends">保存好友设置
            </button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "FriendModal",
  props: {
    showFriend: Boolean,
    sns: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      totalPage: 1,
      currentPage: 1,
      pageSize: 8,
      currentPageData: [],
      headPage: 1,
      friends: {},
      mFriendList: [],
      friendList: {},
      friendIds: [],
      checkedFriend: false,
    }
  },
  methods: {
    getCurrentPageData() {
      let begin = (this.currentPage - 1) * this.pageSize;
      let end = this.currentPage * this.pageSize;
      let m1 = [];
      const that = this;

      for (let i = 0, len = this.mFriendList.length; i < len; i++) {
        if (that.friendIds.includes(this.mFriendList[i].id)) {
          m1.push(this.mFriendList[i])
        }
      }
      for (let i = 0, len = this.mFriendList.length; i < len; i++) {
        if (!that.friendIds.includes(this.mFriendList[i].id)) {
          m1.push(this.mFriendList[i])
        }
      }
      this.mFriendList = m1;
      this.currentPageData = this.mFriendList.slice(begin, end);
    },
    firstPage() {
      this.currentPage = this.headPage;
      this.getCurrentPageData()
    },
    prevPage() {
      if (this.currentPage === 1) {
        return false
      } else {
        this.currentPage--;
        this.getCurrentPageData()
      }
    },
    nextPage() {
      if (this.currentPage === this.totalPage) {
        return false;
      } else {
        this.currentPage++;
        this.getCurrentPageData()
      }
    },
    lastPage() {
      if (this.currentPage === this.totalPage) {
        return false;
      } else {
        this.currentPage = this.totalPage;
        this.getCurrentPageData()
      }
    },
    saveAttentionFriends() {
      console.log("fIds::", [...this.friendIds])
      const fKey = 'friends_' + this.$root.uid + '_' + this.sns.snsId;
      this.$dba.setItem(fKey, this.friendIds)
      this.$gkt.executeAccountApi(this.$root.uid, 'updateAttentionFriends', {},
          {snsNumber: this.sns.snsId, attFriends: [...this.friendIds]})
    },
    pkgFriendList() {
      this.mFriendList = [];
      for (const fItem in this.friends) {
        this.mFriendList.push(this.friends[fItem])
      }
      this.totalPage = Math.ceil(this.mFriendList.length / this.pageSize);
      this.totalPage = this.totalPage === 0 ? 1 : this.totalPage;
      this.getFriends()
      this.getCurrentPageData();
    },
    searchFriendList(e) {
      const key = e.target.value;
      console.log("search:key:", key)
      this.currentPage = 1;
      if (key && this.friendList) {
        let g = {};
        for (let friend in this.friendList) {
          if (this.friendList[friend].payload?.name.indexOf(key) !== -1) {
            g[friend] = this.friendList[friend];
          }
          this.friends = g;
          // console.log("wx-friends::", JSON.stringify(this.friends))
        }
        this.pkgFriendList()
      } else {
        // this.friends = this.friendList;
        this.fetchFriendList();
      }
    },
    setFriendChecked() {
      if (this.friends && this.friendIds.length === Object.keys(this.friends).length) {
        this.checkedFriend = true;
      } else {
        this.checkedFriend = false;
      }
    },

    checkAllFriend() {
      if (this.checkedFriend) {
        for (let fKey in this.friends) {
          if (this.friendIds.indexOf(fKey) === -1) {
            this.friendIds.push(fKey)
          }
        }
      } else {
        this.friendIds = [];
      }
    },

    fetchFriendList() {
      this.getFriendList()
      this.pkgFriendList()
    },
    getFriendList() {
      const mList = this.$dba.getItem('friends_' + this.sns.snsId)
      this.friends = null
      this.friends = mList;
      this.friendList = mList
    },
    //获取本地存储中需要报价的好友列表
    getFriends() {
      const fKey = 'friends_' + this.$root.uid + '_' + this.sns.snsId;
      const _friends = this.$dba.getItem(fKey);
      if (_friends) {
        // 赋值
        this.friendIds = _friends;
      } else {
        if (window.ginkgoTo) {
          this.$gkt.executeAccountApi(this.$root.uid, 'fetchAttentionFriends', {}, {snsNumber: this.sns.snsId})
        }
      }
    },
    closeModal() {
      this.currentPage = 1;
      this.fetchFriendList()
      this.$emit('closeFriend')
    }
  },
  created() {
    const _this = this
    this.$gkt.call('pushMsgHandler', 'FriendModal', msg => {
      const {type, objData} = msg
      if (type === 16003) {
        if (objData.flag !== 'GROUP') {
          if (null !== objData.data) {
            this.friendIds = objData.data
          } else {
            this.friendIds = [];
          }
          const fKey = 'friends_' + this.$root.uid + '_' + this.sns.snsId;
          this.$dba.setItem(fKey, this.friendIds)
        }
      }
      if (type === 16002 && objData.flag === 'FRIEND') {
        console.log('-----16002---')
        _this.closeModal()
        if (objData.code === 200) {
          return ElMessage({
            message: "保存成功",
            type: 'success'
          })
        } else {
          return ElMessage({
            message: "保存失败",
            type: 'error'
          })
        }
      }
    })

    this.fetchFriendList()
  },
  watch: {
    friendIds() {
      this.setFriendChecked()
    }
  }
}
</script>

<style scoped>
.list-group-item {
  color: #ffffff;
  background-color: #1F1B2D !important;
  border-color: #3E3A4A !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 500px;
  margin: auto;
  padding: 10px 10px;
  background-color: #1F1B2D;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border: 1px solid #3E3A4A;
}

.modal-header {
  padding: 0 0 !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 10px 0;
  padding: 0 0 !important;

}

.modal-footer {
  padding: 0 0 !important;
}

.list-group {
  height: 400px;
}

.modal-default-button {
  float: right;
}

/*
 * 对于 transition="modal" 的元素来说
 * 当通过 Vue.js 切换它们的可见性时
 * 以下样式会被自动应用。
 *
 * 你可以简单地通过编辑这些样式
 * 来体验该模态框的过渡效果。
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>