import DBAccess from "@/utils/DBAccess";
import GinkgoToApi from "@/utils/GinkgoToApi";
import store from "@/store";
import xmlParse from "xml-js"
import {marked} from 'marked'
import moment from 'moment'
moment.locale('zh_CN')

import {v4 as uuid} from 'uuid'

try {
  // const
  const renderer = new marked.Renderer();
  const hljs = require('highlight.js');

  hljs.configure({languages: ['go', 'html', 'css', 'js', 'vue', 'go', 'lisp', 'latex']});
  hljs.highlightAll();
  renderer.code = (code, infostring) => {
    const language = (infostring || '').match(/\S*/)[0]
    const validLanguage = hljs.getLanguage(language) ? language : 'vue' === language ? 'html' : 'plaintext'
    let v = hljs.highlight(validLanguage, code).value
    // v = v.replaceAll(/```/g, '')
    return `<pre><div class="code-desc"><span>${language}</span><button type="button" class="btn btn-xs btn-like"><span>Copy</span></button></div><code class="hljs ${validLanguage}">${v}</code></pre>`
  }
  // marked.options = Renderer
  marked.setOptions({renderer})
  // marked.options
} catch (e) {
  console.log('marked error: ', e)
}
// const $dba = DBAccess
export default {
  install: (app, options) => {
    app.config.globalProperties.$dba = DBAccess
    app.config.globalProperties.$gkt = GinkgoToApi
    app.config.globalProperties.$store = store
    app.config.globalProperties.$xmlParse = xmlParse
    app.config.globalProperties.moment = moment // 常用函数
    app.config.globalProperties.$uuid = () => {return uuid()} // 生成唯一id
    app.config.globalProperties.$md = {
      parse: (txt) => {return marked(txt)}
    }
    if (options) {
      //
    }
  }
}
