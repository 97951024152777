<template>
  <div class="h-100 dropdown d-none d-lg-block sns-card-info start-0 position-absolute">
    <a class="d-block py-0 h-100"
       data-bs-toggle="dropdown" role="button" aria-expanded="false">
      <div v-if="sns.avatar"
           :class="'h-100 icon-box-media bg-faded-accent rounded-circle position-relative d-flex justify-content-center align-items-center ' + (sns.isOnline ? '': 'img-gray')"
           :style="'background-image: url('+sns.avatar+');'">
        <div v-show="msgSpinner" class="spinner-border text-warning pb-0 position-absolute" role="status"
             style=""></div>
        <div class="h-100 w-100 position-relative">
          <div class="shadow shadow-sm position-absolute bottom-0 end-0 rounded-circle"
               style="height: 33%; width: 33%; background-color: #07EB00;"></div>
        </div>
      </div>
      <div v-else class="icon-box-media bg-faded-accent text-primary rounded-circle me-2">
        <i class="fi-wechat"></i>
      </div>
    </a>
    <div v-show="dropdownInfo" class="dropdown-menu dropdown-menu-dark"
         :aria-labelledby="'pills-' + sns.snsId?.replace('@', '')"
         :style="'background-color:' + (sns.theme?.bgColor || theme.current) + 'F0'">
      <sns-sample-card :sns="sns"></sns-sample-card>
    </div>
  </div>

  <button :style="sns.theme? ('background-color:' + sns.theme.bgColor + 'A0') : ''" :disabled="!dropdownInfo"
          v-show="showNickname"
          class="sns-nav-link nav-link h-100 btn btn-sm icon-box border border-light card card-light flex-row align-items-center card-hover rounded-pill p-0 px-2"
          :id="'pills-'+sns.snsId?.replace('@', '')" :data-bs-reference="'pills-'+sns.snsId?.replace('@', '')"
          @click="showRoomsBySourceId(sns.snsId)"
          data-bs-toggle="pill" role="tab" type="button" aria-selected="false">
    <span class="text-truncate">{{ sns.name ? sns.name : sns.snsId }}</span>
  </button>
  <button v-show="showTrash" type="button" class=" btn btn-sm p-0 btn-link ms-2" @click="unbindSns(sns.snsId)"><i
      class="fi-trash"></i></button>
</template>
<script>
import SnsSampleCard from "@/components/sns/SnsSampleCard";

export default {
  components: {SnsSampleCard},
  props: {
    uid: {
      type: String,
      required: true
    },
    sns: {
      type: Object,
      required: true
    },
    dropdownInfo: {
      type: Boolean,
      required: false
    },
    showTrash: {
      type: Boolean,
      required: false
    },
    // 是否显示名称，默认为true
    showNickname: {
      type: Boolean,
      required: false,
      default: true
    },
    enableDropdownMenu: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => {
    return {
      msgSpinner: false,
      spinnerTimeId: -1,
      theme: {
        current: '#1f1b2d',
        // 移植到了SnsSampleCard
      }
    }
  },
  created() {

  },
  watch: {
    'sns.msgCount': {
      handler() {
        clearTimeout(this.spinnerTimeId)
        this.msgSpinner = true
        this.spinnerTimeId = setTimeout(() => {
          this.msgSpinner = false
        }, 3000)
      }
    }
  },
  methods: {
    showRoomsBySourceId(sourceId) {
      console.log('sns card show rooms.... ', sourceId)
      this.$parent.showRooms(sourceId)
      // this.roomFilters.sourceIds = [sourceId]
    },
    unbindSns(snsId) {
      const a = confirm('确定要删除这个账号吗？如果删除账号，重启动应用后该账号将不可用！')
      if (a) {
        console.log('删除吧')
        this.$store.commit('unbindSnsId', {userId: this.$root.uid, snsId})
        this.$store.commit('deleteSns', snsId)
        console.log('删除成功')
      } else {
        console.log('再想想')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.sns-list-item-modal {
  .sns-card-info {
    .icon-box-media {
      height: 2rem !important;
      min-width: 2rem !important;
      width: 2rem !important;
      font-size: 0.857rem;
      line-height: 1.35rem;

    }
  }

  .sns-nav-link {
    //border: 1px solid #FFFFFF45;
    // border: 0px !important;
    border-radius: 50rem !important;
    padding-left: 2.4rem !important;
    min-width: 4.2857rem;
    max-width: 7.25rem;
    height: 2rem !important;
  }
}

.sns-list-item {
  .sns-nav-link {
    //border: 1px solid #FFFFFF45;
    // border: 0px !important;
    border-radius: 50rem !important;
    padding-left: 1.88rem !important;
    min-width: 4.2857rem;
    max-width: 7.25rem;

  }
}

.sns-card-info {
  z-index: 99;

  .icon-box-media {
    height: 1.45rem;
    min-width: 1.45rem;
    width: 1.45rem;
    font-size: 0.857rem;
    line-height: 1.35rem;

  }
}

.ginkgoto-no-drag {
  -webkit-app-region: no-drag;
}

</style>
