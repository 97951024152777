<template>

  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <label class="text-white">群列表设置</label>
            <input class="m-2" type="text" style="width: 260px"
                   @input="searchGroupList($event)"
                   placeholder="关键字查询">
            <label class="text-white">
              <input class="form-check-input " type="checkbox" @change="checkAllGroup" v-model="checked"> 全选</label>
          </slot>
        </div>

        <div class="modal-body tab-content py-1">
          <slot name="body">
            <ul class="list-group" v-if="currentPageData.length > 0 ">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                  v-for="groupItem in currentPageData" :key="groupItem.id">
                  <span class="text_content ">{{
                     (sns.type === 'MQ' || sns.type === 'EQQ') ? (groupItem?.topic ? groupItem.topic : groupItem?.payload?.topic) : groupItem?.payload?.topic
                     }}</span>
                <input class="form-check-input" type="checkbox" :id="groupItem.id" :value="groupItem.id"
                       v-model="groupIds">
              </li>
            </ul>
            <div class="text-center text-white" style="height: 400px" v-else-if="sns.type === 'CHAT_GPT'">
              <label>暂无群列表</label></div>
            <div class="text-center text-white" style="height: 400px" v-else><label>数据加载中，请稍后...</label></div>
            <div class="align-content-center mt-1 text-center">
              <input style="width: 80px" value="首页" type="button" @click="firstPage">
              <input class="m-lg-1" style="width: 80px" type="button" value="上一页" @click="prevPage">
              <input type="button" style="width: 80px" value="下一页" @click="nextPage">
              <input class="m-lg-1" style="width: 80px" type="button" value="尾页" @click="lastPage">
            </div>

          </slot>
        </div>

        <div class="modal-footer d-sm-flex justify-content-between pt-2">
          <slot name="footer">
            <button
                class="btn btn-secondary d-block  fixed-bottom-btn" style="width: 13rem"
                @click="closeModal">关闭
            </button>
            <button class="btn btn-primary d-block  fixed-bottom-btn" type="button" style="width: 13rem"
                    @click="saveAttentionGroups">保存群设置
            </button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>

</template>

<script>

import {ElMessage} from "element-plus";

export default {
  name: "GroupModal",
  props: {
    show: Boolean,
    sns: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      totalPage: 1,
      currentPage: 1,
      pageSize: 8,
      currentPageData: [],
      headPage: 1,
      groups: {},
      mGroupList: [],
      groupList: {},
      groupIds: [],
      checked: false
    }
  },
  watch: {
    groupIds() {
      this.setGroupChecked()
    }
  },
  methods: {
    closeModal() {
      this.currentPage = 1
      this.fetchGroupList()
      this.$emit('close')
    },
    searchGroupList(e) {
      const key = e.target.value;

      this.currentPage = 1;
      if (key && this.groupList) {
        let g = {};
        for (const group in this.groupList) {
          // const group = this.groupList[groupId]
          // console.log("内容:", group)
          if (this.groupList[group]?.payload?.topic?.indexOf(key) !== -1) {
            g[group] = this.groupList[group];
          }
          this.groups = g;
          // console.log("wx-groups::", JSON.stringify(this.groups))
        }
        this.pkgGroupList()
      } else {
        // this.groups = this.groupList;
        this.fetchGroupList()
      }
    },
    getGroups() {
      const gKey = 'groups_' + this.$root.uid + '_' + this.sns.snsId;
      // console.log('gKey-->', gKey)
      const _groups = this.$dba.getItem(gKey);
      if (_groups) {
        // 赋值
        this.groupIds = _groups;
        // console.log("this.groupIds:::", this.groupIds)
      } else {
        console.log("服务器获取关注的群列表")
        this.$gkt.executeAccountApi(this.$root.uid, 'fetchAttentionGroups', {}, {snsNumber: this.sns.snsId})
      }
    },
    pkgGroupList() {
      this.mGroupList = [];
      for (const key in this.groups) {
        this.mGroupList.push(this.groups[key])
      }
      this.totalPage = Math.ceil(this.mGroupList.length / this.pageSize);
      this.totalPage = this.totalPage === 0 ? 1 : this.totalPage;
      this.getGroups()
      this.getCurrentPageData();
    },
    getRooms() {
      const rooms = this.$dba.getItem('rooms_' + this.sns.snsId)
      this.groups = null
      this.groups = rooms;
      this.groupList = rooms;
      this.pkgGroupList();
    },
    fetchGroupList() {
      this.currentPage = 1;
      this.getRooms()
    },

    checkAllGroup() {
      if (this.checked) {
        for (let gKey in this.groups) {
          if (this.groupIds.indexOf(gKey) === -1) {
            this.groupIds.push(gKey)
          }
        }
      } else {
        this.groupIds = [];
      }
    },
    saveAttentionGroups() {
      const gKey = 'groups_' + this.$root.uid + '_' + this.sns.snsId;
      // console.log('groupIds--->',this.groupIds)
      this.$dba.setItem(gKey, this.groupIds)
      this.$gkt.executeAccountApi(this.$root.uid, 'updateAttentionGroups', {},
          {snsNumber: this.sns.snsId, attGroups: [...this.groupIds]})
    },
    setGroupChecked() {
      if (this.groups && this.groupIds.length === Object.keys(this.groups).length) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    groupBy(list, pros) {
      return list.reduce((prev, item) => {
        let key = item[pros]
        if (!prev[key]) {
          prev[key] = []
        }
        prev[key].push(item)
        return prev
      }, {})
    },
    getCurrentPageData() {
      let begin = (this.currentPage - 1) * this.pageSize;
      let end = this.currentPage * this.pageSize;
      let m1 = [];
      const that = this;
      for (let i = 0, len = this.mGroupList.length; i < len; i++) {
        if (that.groupIds.includes(this.mGroupList[i]?.id)) {
          m1.push(this.mGroupList[i])
        }
      }
      for (let i = 0, len = this.mGroupList.length; i < len; i++) {
        if (!that.groupIds.includes(this.mGroupList[i]?.id)) {
          m1.push(this.mGroupList[i])
        }
      }
      this.mGroupList = m1;
      this.currentPageData = this.mGroupList.slice(begin, end)
    },

    firstPage() {
      this.currentPage = this.headPage;
      this.getCurrentPageData()
    },
    prevPage() {
      if (this.currentPage === 1) {
        return false
      } else {
        this.currentPage--;
        this.getCurrentPageData()
      }
    },
    nextPage() {
      if (this.currentPage === this.totalPage) {
        return false;
      } else {
        this.currentPage++;
        this.getCurrentPageData()
      }
    },
    lastPage() {
      if (this.currentPage === this.totalPage) {
        return false;
      } else {
        this.currentPage = this.totalPage;
        this.getCurrentPageData()
      }
    },
  },
  created() {
    const _this = this
    this.$gkt.call('pushMsgHandler', 'GroupModal', msg => {
      const {type, objData} = msg
      if (type === 16002 && objData.flag === 'GROUP') {
        _this.$emit('close')
        if (objData.code === 200) {
          return ElMessage({
            message: "保存成功",
            type: 'success'
          })
        } else {
          return ElMessage({
            message: "保存失败",
            type: 'error'
          })
        }
      }
      if (type === 16003) {
        if (objData.flag === 'GROUP') {
          if (null !== objData.data) {
            this.groupIds = objData.data;
          } else {
            this.groupIds = [];
          }
          const gKey = 'groups_' + this.$root.uid + '_' + this.sns.snsId;
          this.$dba.setItem(gKey, this.groupIds)
        }
      }
    })
    this.fetchGroupList()
  }
}
</script>

<style scoped>
.list-group-item {
  color: #ffffff;
  background-color: #1F1B2D !important;
  border-color: #3E3A4A !important;
}

.text_content {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 500px;
  margin: auto;
  padding: 10px 10px;
  background-color: #1F1B2D;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border: 1px solid #3E3A4A;
}

.modal-header {
  padding: 0 0 !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 10px 0;
  padding: 0 0 !important;

}

.modal-footer {
  padding: 0 0 !important;
}

.list-group {
  height: 400px;
}

.modal-default-button {
  float: right;
}

/*
 * 对于 transition="modal" 的元素来说
 * 当通过 Vue.js 切换它们的可见性时
 * 以下样式会被自动应用。
 *
 * 你可以简单地通过编辑这些样式
 * 来体验该模态框的过渡效果。
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>