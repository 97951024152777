<template>

  <header :class="'navbar navbar-expand-lg  navbar-dark fixed-top navbar-stuck ' + drag"
          :onmousedown="activeDrag" data-scroll-header v-if="header_show">
    <div class="ginkgoto-title-bar">
      <div class="position-relative w-100 h-100 text-center text-light py-1 d-none">

      </div>
    </div>
    <div class="ginkgoto-navbar container" style="max-width: 100%">
<!--      <div class="ginkgoto-drag nav-link navbar-brand me-3 me-xl-4 px-0" style="cursor: move;">
        <img class="d-block" style="height: 2.8rem"
             :src="oemSetting?.logo || 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/logo-infomatrix.png'"
             alt="Finder">
      </div>-->
      <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"><span
          class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse order-lg-2 z-menu" id="navbarNav">
        <ul class="navbar-nav navbar-nav-scroll w-100 d-flex justify-content-start" style="">
          <!-- Menu items-->
          <!-- 应用列表 -->
          <li class="nav-item dropdown py-2 ginkgoto-no-drag">
            <!--            <router-link class="nav-link z-auto-close" to="/home">首页</router-link>-->

            <router-link :onmouseover="inactiveDrag" :onmouseout="activeDrag"
                         class="nav-link dropdown-toggle d-flex align-items-center border-end-lg border-light py-1 pe-lg-3"
                         :to="{path: '/'}"
                         aria-expanded="false"><i class="fi-layers me-2"></i> 首页
            </router-link>
            <ul class="dropdown-menu dropdown-menu-dark" v-show="!isShow">
              <template v-if="oemMenus['' + user?.from]?.menus">
                <li v-for="(om, index) in oemMenus['' + user?.from]?.menus" :key="index">
                  <router-link class="dropdown-item z-auto-close text-warning"
                               :to="{name: 'iframe', query: {src: om.url}}">
                    <i :class="'me-2 ' + om.icon"></i>{{ om.name }}
                  </router-link>
                </li>
                <li class="dropdown-divider"></li>
              </template>

              <li><a class="dropdown-item z-auto-close" href="#/insider/company"><i
                  class="fi-building fs-base me-2"></i>企业/店铺管理</a></li>
              <li><a class="dropdown-item z-auto-close" href="#/insider/erp"><i
                  class="fi-home fs-base me-2"></i>绑定ERP</a></li>
              <li><a class="dropdown-item z-auto-close d-none" href="#/insider/erpIndex"><i
                  class="fi-home fs-base me-2"></i>本地ERP绑定</a></li>
              <li><a class="dropdown-item z-auto-close d-none" href="#/autoReplay"><i
                  class="fi-home fs-base me-2"></i>自动报价</a></li>
              <li><a class="dropdown-item z-auto-close d-none" href="#"><i
                  class="fi-list fs-base me-2"></i>添加应用组件</a></li>
              <li><a class="dropdown-item z-auto-close d-none" href="#/setting"><i
                  class="fi-file fs-base me-2"></i>设置</a></li>
              <li class="dropdown-divider"></li>
              <!-- <li>
                 <router-link class="dropdown-item z-auto-close"
                              :to="{name: 'iframe', query: {src: '/bis/zq/search?_s=shbqq&_from=epc'}}"><i
                     class="fi-building fs-base me-2"></i>北四环汽配城
                 </router-link>
               </li>-->
              <!-- <li>
                  <router-link class="dropdown-item z-auto-close"
                               :to="{name: 'iframe', query: {src: '/bis/zq/search?_s=shbqq&_from=epc'}}"><i
                      class="fi-building fs-base me-2"></i>北四环汽配城
                  </router-link>
                </li>-->
              <!-- <li>
                 <span class="dropdown-item z-auto-close"
                       v-on:click="searchMj()"><i
                     class="fi-car fs-base me-2"></i>电子零件目录
                 </span>
               </li>-->
              <li><a class="dropdown-item z-auto-close d-none" href="#"><i
                  class="fi-briefcase fs-base me-2"></i>公众号运营</a></li>
              <li>
                <router-link class=" dropdown-item p-0 my-1 z-auto-close"
                             to="/insider/apps/routine-shop-adv/0">
                  <img src="https://res.wx.qq.com/wxaproducttrade/dist/images/nav_ac540b.svg"
                       style="height: 1.15rem;"/><span class="">小程序（定制版）</span>
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item z-auto-close"
                             :to="{name: 'iframe', query: {src: 'http://cms.ginkgoto.com/admin'}}"><i
                    class="fi-globe fs-base me-2"></i>门户网站管理
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item z-auto-close"
                             :to="{name: 'iframe', query: {src: 'https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=ww976bc5049829141a&agentid=1000012&redirect_uri=http://autoinfotech.cn/workwechat-auth/login/wechat/inside/qrLogin/62be9440d85f61297e5242ce/1000012&state=autoinfotech.cn'}}">
                  <i
                      class="fi-accounting fs-base me-2"></i>会话存档（AutoTape）
                </router-link>
              </li>

            </ul>
          </li>

          <li class="nav-item ginkgoto-no-drag d-none">
            <a class="nav-link z-auto-close d-flex align-items-center" href="javascript:;"
               v-on:click="searchEPC()">EPC
            </a>
          </li>
          <li class="nav-item d-none">
            <router-link class="nav-link z-auto-close " to="/log">日志</router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link z-auto-close" to="/insider/gm">群发</router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link z-auto-close" to="/insider/chat">聊天</router-link>
          </li>
          <li class="nav-item d-lg-none">
            <!--            <a v-if="user" href="javascript:;">退出（Sign Out）</a>-->
            <div v-if="user && user.isOnline" class="ginkgoto-no-drag">
              <account-info-card :user="user" :power="power" :stop="stop"></account-info-card>
            </div>
            <router-link v-else class="nav-link z-auto-close" to="/"><i
                class="fi-user me-2"></i>登录
            </router-link>
          </li>
          <!-- 全局搜索 -->
          <li :class="['nav-item' ,isShow ? 'd-sm-block':'d-none']">
            <form
                class="form-group ginkgoto-no-drag form-group-light d-block d-md-flex position-relative rounded-md-pill p-0 m-1">
              <div class="input-group  input-group-sm">
                <span class="input-group-text text-muted rounded-pill ps-3"><i
                    class="fi-search"></i></span>
                <input ref="inputRef" v-model="vinInput" v-on:keyup.enter="searchGoods()"
                       class="form-control"
                       type="text"
                       placeholder="搜索OE码或VIN码...">
              </div>
              <div class="d-sm-flex">
                <div class="dropdown d-none w-100 mb-sm-0" data-bs-toggle="select">
                  <button class="btn nav-link h-100 btn-xs dropdown-toggle ps-2 ps-sm-3 py-1 d-flex align-items-center"
                          type="button"
                          data-bs-toggle="dropdown" aria-expanded="false"><i class="fi-list me-2"></i><span
                      class="dropdown-toggle-label">车型数据（VIN）</span></button>
                  <input type="hidden">
                  <ul class="dropdown-menu dropdown-menu-dark my-0" style="margin-top: 0 !important;">
                    <li><span class="dropdown-item" href="#"><i
                        class="fi-bed fs-sm  opacity-60 me-2"></i><span
                        class="dropdown-item-label">所有</span></span></li>
                    <li><span class="dropdown-item" href="#"><i
                        class="fi-shopping-bag fs-sm opacity-60 me-2"></i><span
                        class="dropdown-item-label">商品</span></span></li>
                    <li><span class="dropdown-item" href="#"><i
                        class="fi-shop fs-sm opacity-60 me-2"></i><span
                        class="dropdown-item-label">店铺</span></span></li>
                    <li><span class="dropdown-item" href="#"><i
                        class="fi-museum fs-sm opacity-60 me-2"></i><span
                        class="dropdown-item-label">汽配城</span></span></li>
                    <li><span class="dropdown-item" href="#"><i
                        class="fi-makeup fs-sm opacity-60 me-2"></i><span
                        class="dropdown-item-label">修理厂</span></span></li>
                    <li><span class="dropdown-item" @click="searchGoods"><i
                        class="fi-gearbox fs-sm opacity-60 me-2"></i><span
                        class="dropdown-item-label">配件（OE）</span></span></li>
                    <li><span class="dropdown-item" href="#"><i
                        class="fi-car fs-sm opacity-60 me-2"></i><span
                        class="dropdown-item-label">车型数据（VIN）</span></span></li>

                  </ul>
                </div>
                <button class="btn btn-primary btn-xs rounded-pill w-100 w-md-auto ms-sm-3"
                        type="button"
                        v-on:click="searchGoods()">搜索
                </button>
              </div>
            </form>
          </li>


        </ul>
      </div>
      <!-- Button with growing spinner and text -->
      <div class="btn-toolbar ms-2 order-lg-3 d-none" role="toolbar" aria-label="Settings toolbar">

        <div class="btn-group btn-group-sm d-none" role="group" aria-label="Settings group">
          <button v-show="!powerBtnLoading" type="button" class="btn btn-secondary btn-sm"
                  style="width: 8.5rem"
                  v-on:click="power">
            <i :class="wssBtnSts.icon + ' me-2'"></i>{{ wssBtnSts.text }}
          </button>
          <button v-show="powerBtnLoading" type="button" class="btn btn-secondary btn-sm"
                  style="width: 8.5rem">
            <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
      </div>
      <div class="order-lg-3 d-flex align-items-center" :onmouseover="inactiveDrag" :onmouseout="activeDrag">
        <div class="position-relative d-flex justify-content-center align-items-center"
             style="width: 2rem; height: 2rem;">
          <div v-show="msgSpinner" class="spinner-border text-warning pb-0 position-absolute"
               role="status"></div>
          <a href="javascript:;" class="position-absolute rounded-circle" style="line-height: 0rem;">
            <i :class="msgIcon"></i>
            <!--          <span v-show="wssBtnSts.status" class="text-wrap"></span>-->
            <!--          <span v-show="!wssBtnSts.status" class="">&#45;&#45;</span>-->
          </a>
        </div>

        <div class="btn btn-link btn-light btn-sm d-none d-lg-block p-1">
          <account-info-card v-if="user && user.isOnline" :user="user" :power="power"
                             :stop="stop"></account-info-card>
          <router-link v-else class="btn btn-primary btn-xs" to="/"><i class="fi-user me-2"></i>登录
          </router-link>
        </div>

      </div>

    </div>
  </header>

  <GlobalModals></GlobalModals>
  <main class="webp page-wrapper ginkgoto-frame position-relative">
    <div class="ginkgoto-view position-absolute h-100">
      <router-view/>
    </div>
  </main>
  <footer class="m-0 w-100 bg-faded-success text-light p-1 app-footer" v-if="foot_show">
    <div class="ginkgoto-status-bar d-flex align-items-center justify-content-start" style="font-size: 0.618rem;">
      <span class="opacity-50">内测版 v{{ versionInfo }} </span>
      <div v-show="dialogVisible" class="ms-2 h-100" style="width: 15rem">
        <el-progress :percentage="percentage" :status="progressStatus"/>
      </div>
      <div class="ginkgoto-status-msg flex-grow-1 d-flex justify-content-end pe-2 opacity-80">
        <div class="d-flex align-items-center">
          <span class="font-monospace me-3 opacity-50 d-none">
            GPT ENGINE [{{ $root.aiChat?.version() }}] &nbsp;
            内测版 v{{ versionInfo }} Beta.</span>
          <ul class="sns-list nav nav-pills nav-pills-light align-items-center me-2">
            <li class="nav-item sns-list-item d-none">
              <a class="" href="javascript:;" @click="offcanvasVipWarning.visibleEnd = true"><img
                  style="height: 1rem;"
                  src="https://res.wx.qq.com/wxaproducttrade/dist/images/nav_ac540b.svg"/></a>
            </li>
            <li class="nav-item sns-list-item me-1 d-none">|</li>
            <li v-for="sns in bindSnsList" v-bind:key="sns.snsId"
                class="nav-item sns-list-item position-relative">
              <sns-info-card :dropdown-info="false" :sns="sns" :uid="uid"
                             :show-nickname="false"></sns-info-card>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>

  <COffcanvas placement="bottom" :visible="offcanvasVipWarning.visibleEnd"
              @hide="() => { offcanvasVipWarning.visibleEnd = !offcanvasVipWarning.visibleEnd }">
    <COffcanvasHeader class="bg-faded-warning p-3">
      <COffcanvasTitle>{{ offcanvasVipWarning.title }}</COffcanvasTitle>
      <CCloseButton class="text-reset" @click="() => { offcanvasVipWarning.visibleEnd = false }"/>
    </COffcanvasHeader>
    <COffcanvasBody class="bg-faded-warning pt-0">
      <div class=" px-2 ">
        <img class="rounded" style="height: 8rem;"
             :src="'//zhycit-static.oss-cn-beijing.aliyuncs.com/ui/wx1.jpg'">
      </div>
    </COffcanvasBody>
  </COffcanvas>

</template>
<script>
// import {useStore} from 'vuex'
import AccountInfoCard from "@/components/sns/AccountInfoCard";
import LogMsgHandler from "@/components/message/handlers/GlobalHandler";
import SnsMsgHandler from "@/components/message/handlers/SnsMsgHandler";
import GlobalModals from "@/components/GlobalModals";
import {ElMessage} from "element-plus";
import {COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, COffcanvasBody} from '@coreui/vue'
import SnsInfoCard from "@/components/sns/SnsInfoCard";

export default {
  components: {
    GlobalModals, AccountInfoCard, SnsInfoCard,
    COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, COffcanvasBody,
  },
  data: () => {
    return {
      refTheme: 'dark',
      // user: undefined,
      showNotice: true,
      drag: 'ginkgoto-drag',
      cacheQueueLength: 100,
      powerBtnLoading: false,
      wssBtnInfo: {
        started: {icon: 'fi-power', text: '停止', btn: 'btn-primary', status: true},
        stopped: {text: '启动', icon: 'fi-play', btn: 'btn-secondary', status: false}
      },
      vinInput: "",
      wssBtnSts: {status: true},
      wssStatus: -1,
      msgSpinner: false, // 有消息来时设置成true
      msgSpinnerTID: 0, // 定时器id
      msgTypeIcons: {
        '0': 'fi-alert-triange',
        '1': 'fi-login',
        '3': 'fi-messenger',
        '10': 'fi-link',
        '11': 'fi-heart-filled',
        '60': 'fi-play-filled',
        '61': 'fi-checkbox',
        '27': 'fi-user-check',
        '30': 'fi-instagram',
        '10001': 'fi-wechat text-success',
        '10002': 'fi-users',
        '10003': 'fi-user-plus',
      },
      msgIcon: 'fi-cup text-secondary',
      msgDefaultIcon: 'fi-alert-octagon text-warning',
      // wallet: {}
      vipInfo: {
        isVip: false,
      },
      // fixme: 删除这里的目录配置，由接口获取（测试用）
      oemSetting: null,
      oemMenus: {
        '18': {
          name: '微私域',
          theme: 'bg-light',
          rightSliderWidth: 28,
          home: 'http://www.wesiyu.com/',
          logo: 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/ui/Allinone.png'
        },
        '11': {
          name: '长远ERP',
          theme: 'bg-light',
          rightSliderWidth: 33,
          home: 'http://www.longsky.com/',
          logo: 'https://zhycit-sns.oss-cn-beijing.aliyuncs.com/userImg/head/cy.png',
          loginExitUrl: "https://m.longsky.com/ec/Handler/LongskyHandler.ashx",
          handlers: {
            onStop: {
              uri: 'https://m.longsky.com/ec/Handler/LongskyHandler.ashx',
              params: {
                action: 'CustomerStatus',
                type: 'exit'
              }
            }
          },
          menus: [
            {name: '商机', type: 'link', url: '/qidian-server/ginkgo/page/isvHome', icon: 'fi-globe'},
            {name: '统计', type: 'link', url: '/qidian-server/ginkgo/page/dataHome', icon: 'fi-tv'},
            {
              name: '设置',
              type: 'link',
              url: '/qidian-server/ginkgo/page/isvSetup',
              icon: 'fi-filter-alt-horizontal'
            }]
        },
        '7': {
          name: '识货宝',
          theme: 'bg-light',
          rightSliderWidth: 28,
        }
      },
      oemFrom: 0,
      isShow: true,
      offcanvasVipWarning: {
        visibleEnd: false,
        title: 'VIP即将过期',
        metaDay: {},
        history: []
      },
      parts: false,
      inputRef: null,
      dialogVisible: false,
      progressStatus: "",
      percentage: 0,
      versionInfo: null,
      gptAuthInfo: {},
      header_show: true,
      foot_show: true,
    }
  },
  setup() {

    // 初始化GPT
    const initGPT = () => {
      if (window['ChatGPT']) {
        return new window.ChatGPT({
          // apiBaseUrl: 'http://cloud.nubeshing.com/prod-api',
          apiBaseUrl: 'https://api.zhycit.com/chatai/',
          serviceUrl: '/static/openai/js/api/chat',
          onOpen: (msg) => {
            console.log('[ai chat on open]', msg)
          },
          onMessage: (msg) => {
            console.log('[complete msg when done]:', msg)
          },
          onToken: () => {
            // console.log('[token reponse by chatgpt]:', token)
          },
          onError(err) {
            console.log(err)
          },
          stopChatAi() {
            aiChat.stop()
          },
          // FIXME: 失效后通过远程服务器获取
          accessKey: 'fd81a6c3b880ea3fd36d299045ac618e',
          enableRender: true,
          componentId: 'currentChatBox',
          containerClass: '.chatbox-message-container',
        })
      } else {
        return null
      }
    }
    let aiChat = null // initGPT()


    // const uid = () => {
    //   return store.getters.currentUid()
    // }
    return {
      // ginkgoTo,
      // MsgType,
      aiChat,
      initGPT,
      loadChatAiJS(handler) {
        aiChat = initGPT()
        console.log('进入了loadChatAiJS', aiChat !== null)
        // if (null === aiChat) {
        //   const iid = setInterval(() => {
        //     if (window['ChatGPT']) {
        //       try {
        //         aiChat = initGPT()
        //       } catch (e) {
        //         console.error(e)
        //       }
        //       clearInterval(iid)
        //     }
        //   }, 200)
        // }
        if ('function' === (typeof handler)) {
          handler()
        }
      },
      requestAiMsg(msg) {
        if (aiChat) {
          // console.log('ChatGPT requestAiMsg：' + msg)
          aiChat?.chat(msg)
        } else {
          console.log('ChatAI not found!')
        }
      },
      setAiMsgContent(id) {
        if (aiChat) {
          aiChat.componentId = id
        }
      },
      setAiHandler(name, h) {
        if (aiChat) {
          aiChat[name] = (o) => {
            h(o)
          }
        }
        console.log(`设置GPT回调事件: [${name}] ${aiChat !== null} `)
      },
      setAiMessageHandler(h) {
        this.setAiHandler('onMessage', h)
      },
      setAiErrorHandler(h) {
        if (aiChat) {
          aiChat.onError = (error) => {
            h(error)
          }
        }
      },
      setAiTokenHandler(h) {
        if (aiChat) {
          aiChat.onToken = (token) => {
            h(token)
          }
        }
      },
      stopAiChat() {
        if (aiChat && this.aiReading()) {
          aiChat.stop()
          console.log('aiChat stop = ' + aiChat.reading)
        }
      },
      aiReading: () => {
        console.log('aiReading: ' + aiChat?.reading)
        return aiChat?.reading
      },
      aiChatVersion() {
        return aiChat?.version()
      },
      setAiContentMsg(cm) {
        if (aiChat) {
          aiChat.messages = cm
        }
      },
      getSnsList() {
        const id = this.uid()
        console.log("uid===>", id);
      }
    }

  },
  computed: {

    uid() {
      // console.log('获取uid:' + this.store.getters.currentUid())
      return this.$store.getters.currentUid()
    },
    user() {
      let _user;
      if (this.uid) {
        _user = this.$store.getters.fetchUser(this.uid)
      } else {
        _user = null;
      }
      return {..._user}
    },
    bindSnsList() {
      let _bindSnsList = this.$store.getters.snsList;
      /*const user = this.user;
      if (user) {
        const {bindSnsMap} = user
        if (bindSnsMap) {
          for (const key in bindSnsMap) {
            const sns = this.$store.getters.fetchSns(key)
            if (sns) {
              _bindSnsList.push(sns)
            }
          }
        }
      }*/
      console.log('_bindSnsList---->',_bindSnsList)
      return _bindSnsList
    }
  },
  methods: {
    nothing() {// invalidate
    },
    upsertSns(sns) {
      if ('WX_WEB' === sns.type) {
        sns.avatar = window.ginkgoTo?.RES_SCHEME + sns.type + '/' + sns.snsId + '/' + sns.snsId + '.jpg'
      }
      const _newSns = {...sns, updateTime: new Date().getTime()}
      this.$store.commit('upsertSns', _newSns)
    },
    getSns(snsId) {
      return this.$store.getters.fetchSns(snsId)
    },
    invalidateCaches() {
      // 清除本地缓存，服务的配置信息不会被清除
      const a = confirm('你确定要清除本机缓存吗？清除缓存后您需要重新登录相关账号后使用！')
      if (a) {
        //清除缓存之前，先退出SNS账户
        this.bindSnsList.forEach(_sns => {
          this.$gkt.executeSnsApi(this.uid, _sns.snsId, _sns.type, 'logout', {snsId: _sns.snsId})
        })
        window.localStorage.clear()
      }
    },
    goToHome() {
      console.log("aaa")
      this.$router.go({
        to: '/home'
      })
    },
    searchMj() {
      // console.log("vinInput", this.vinInput, this.uid, 'this.user=', this.user.from)
      if (this.vinInput.length === 17) { // vin码查询
        this.$router.push({
          name: 'iframe',
          query: {src: "/bis/mjPlugin/webPage?vin=" + this.vinInput + "&from=" + this.user.from}
        })
      } else { //
        if (this.$route.path !== '/goods') {
          this.$router.push({
            name: 'iframe',
            query: {src: "/bis/mjPlugin/webPage?vin=" + this.vinInput + "&from=" + this.user.from}
          })
        } else {
          this.$router.push({path: '/goods', query: {oe: this.vinInput}});
        }
      }
    },
    searchEPC() {
      this.$router.push({
        name: 'iframe',
        query: {src: "/bis/mjPlugin/webPage?vin=" + this.vinInput + "&from=" + this.user.from}
      })
    },
    searchGoods() {
      if (this.vinInput) {
        if (this.vinInput.length !== 17) {
          this.$router.push({path: '/goods', query: {oe: this.vinInput}});
        } else {
          this.$router.push({path: '/vinPage', query: {vinCode: this.vinInput}});
        }

      } else {
        return ElMessage({
          message: "请输入OE码",
          type: 'warning'
        })
      }
    },
    onFocus(e) {
      e.stopPropagation();
    },
    power() {
      console.log('重启动服务')
      this.stop()
      setTimeout(() => {
        this.start()
      }, 3000)
      // this.reload()
    },
    start() {
      console.log('调用了启动方法')
      this.$store.commit('initStatus', false)
      // window.ginkgoTo.start(this.uid)
      this.$gkt.call('start', this.uid)
    },
    stop() {
      console.log('调用了停止方法')
      this.$gkt.call('stop', (this.uid))
      this.wssStatus = -1
      this.wssBtnSts = this.wssBtnInfo.stopped
    },
    autoStartSns() {
      // input: msg, view
      // TODO: 检查全局配置，如果设置了自动启动，则执行下面的代码，如果没有，则不做任何操作
      // console.log('auto start sns check: ', msg)

    },
    pushMsgToHistory(key, msg) {
      let _msgHistory = this.$dba.getItem(key, [])
      // console.log("_msgHistory:", JSON.stringify(_msgHistory))
      if (this.cacheQueueLength <= _msgHistory.length) {
        _msgHistory.splice(0, _msgHistory.length - this.cacheQueueLength + 1)
      }
      _msgHistory.push(msg)
      this.$dba.setItem(key, _msgHistory)
    },
    //TODO 判断所有的自动回复的设置信息
    checkReplaySettings(snsId, msg) {
      const {msgType, wrapRoomId, wrapTalkerId, subType} = msg;
      let gKey = "groups_" + this.uid + "_" + snsId;
      let attGroups = this.$dba.getItem(gKey);
      // console.log("attGroups:", attGroups)
      let fKey = "friends_" + this.uid + "_" + snsId
      const attFriends = this.$dba.getItem(fKey);
      // console.log("attFriends:", attFriends);
      if (msgType === "group" && subType === "normal") { // 群信息
        if (attGroups && attGroups.indexOf(wrapRoomId + '') > -1) {
          return true
        }
        return false
      } else if (msgType === "private" && subType === "group") { // 临时消息
        return true
      } else if (msgType === "private" && subType === "friend") { //私聊
        if (attFriends && attFriends.indexOf(wrapTalkerId + '') > -1) {
          return true
        }
        return false
      }
    },
    activeDrag() {
      this.drag = 'ginkgoto-drag'
    },
    inactiveDrag() {
      this.drag = 'ginkgoto-no-drag'
    },
    updateUser() {
      //TODO 这个暂时有问题
      console.log("更新user")
      this.$gkt.call('accountIsOnline', (info) => {
        if (info) {
          console.log("有数据")
        } else {
          this.$store.commit('upsertUser', null)
          this.$store.commit('setCurrentUid', null)
          console.log("设置为空")
        }
      })
    },
    savePromoteHistory(msg, rooms, uid, points) {
      if (rooms && rooms.length > 0) {
        let audience = 0
        rooms.forEach(room => audience += room.payload?.member_count || room.payload?.memberIdList?.length || 1)

        const timestamp = new Date().getTime()
        const _promoteHistoryKey = 'promote_history_' + uid + '_' + timestamp
        let promoteHistoryKeys = this.$dba.getItem('promote_history_keys_' + uid, {})
        promoteHistoryKeys[_promoteHistoryKey] = {
          name: msg,
          key: _promoteHistoryKey,
          count: rooms.length,
          createTime: this.moment(timestamp).format('llll'),
          timestamp,
          finished: 0,
          lastMessage: msg,
          points: points,
          audience
        }
        this.$dba.setItem('promote_history_keys_' + uid, promoteHistoryKeys)
        this.$dba.setItem(_promoteHistoryKey, rooms)

        return _promoteHistoryKey
      }
      return null
    },
    signIn(params) {
      console.log("info--->", params)
      const _that = this
      _that.$gkt.call('loginAccount', params,
          (result) => {
            // console.log("autologinAccount")
            if (result.status === 1) {
              this.$store.commit('upsertUser', {...result?.data})
              this.$store.commit('setCurrentUid', {userId: result?.data?.userId})
              // const _user = this.store.getters.fetchUser(info?.userId)
              let _oemSetting = {}
              if (_that.user) {
                _that.user.isOnline = true
                // FIXME: 改为动态从数据库获取，可以参考使用统一的接口标准（推送标准）
                // console.log('这里来了吗？')
                _that.oemSetting = _that.oemMenus[_that.user?.from]
                _oemSetting = {..._that.oemSetting}
                if (_oemSetting?.handlers) {
                  // window.ginkgoTo['pushOemHandler']('onStart', 'https://zhycit.com', {abc: 'start'})
                  const {onStop} = _oemSetting?.handlers
                  if (onStop) {
                    this.$gkt.call('pushOemHandler', 'onStop', onStop.uri, {
                      ...onStop.params,
                      receid: _that.user.userId
                    })
                    // window.ginkgoTo['pushOemHandler']('onStop', onStop.uri, {
                    //   ...onStop.params,
                    //   receid: _that.user.userId
                    // })
                  }
                }
              }
              _that.start()
              //如果是长远的版本，则需要请求长远的登录接口
              if (_that.user?.from === 11) {
                const jsonData = {action: 'CustomerStatus', receid: _that.user?.userId, type: 'login'}
                _that.$gkt.call('loginLongSky', _oemSetting?.loginExitUrl, jsonData)
              }
              // this.$router.push('/home')
              if (_oemSetting?.home) {
                console.log('ome?push', _oemSetting?.home)
                this.$router.push({name: 'iframe', query: {src: _oemSetting?.home}})
              } else {
                _that.$router.push('/')
              }
              this.loginChatGPT(_that.user)
            } else {
              if ('login' === _that.$route.name) {
                return ElMessage({
                  message: result.msg,
                  type: 'error'
                })
              } else {
                _that.$router.push({name: 'login'})
              }
            }

          },
          (wallet, userId) => {
            // this.wallet = wallet
            if (userId) {
              const _user = _that.$store.getters.fetchUser(userId)
              if (_user) {
                _that.$store.commit('upsertUser', {..._user, wallet})
              }
            }
            // console.log('wallet', wallet)
          },
          (createTime, _totalQuantity, _daysNum, userId) => {
            try {
              _that.vipInfo = {
                isVip: true,
                totalQuantity: _totalQuantity,
                createTime: createTime,
                vipDesc: _totalQuantity ? 'VIP于' + _that.moment(_totalQuantity).format('LL') + '到期' : 'VIP已过期',
                // daysNum: parseInt(_daysNum / (1000 * 60 * 60 * 24) + '')
                daysNum: _daysNum
              }
              // console.log('vip时长：', _totalQuantity)
              const _user = _that.$store.getters.fetchUser(userId)
              _that.$store.commit('upsertUser', {..._user, ..._that.vipInfo})
              if (_totalQuantity < new Date().getTime()) {
                // this.$store.comm
                _that.$store.commit('unbindAllSns', {userId: _user.userId})
              }
              console.log('剩余时间--', (_totalQuantity - new Date().getTime()))
              const _times = _totalQuantity - new Date().getTime();
              if (_times < 1000 * 60 * 60 * 72 && _times > 0) {
                // this.offcanvasVipWarning.visibleEnd = true
                this.offcanvasVipWarning.title = 'VIP即将过期'
              } else if (_times <= 0) {
                // this.offcanvasVipWarning.visibleEnd = true
                this.offcanvasVipWarning.title = 'VIP已过期'
              }
            } catch (e) {
              console.error(e)
            }

          },
          (settings, userId) => {
            try {
              // console.log('成功获取了设置信息', settings, '\n' + userId)
              if (userId) {
                const _user = _that.$store.getters.fetchUser(userId)
                // console.log("_user:", _user)
                _that.$store.commit('upsertUser', {..._user, settings})
              }
            } catch (e) {
              console.error(e)
            }
          })
    },

    downloadUpdate() {
      setTimeout(() => {
        // window.ginkgoTo.downloadFile()
        this.$gkt.call('downloadFile')
      }, 2000);
    },
    loginChatGPT(user) {
      const _uid = user?.userId || this.uid
      if (_uid) {
        this.$gkt.executeSnsApi(_uid, _uid, 'CHAT_GPT', 'login', {
          snsId: _uid,
          uid: _uid,
          user: {
            name: user?.userName,
            snsId: _uid,
            avatar: "https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/ChatGPT.png"
          }
        })
      }
    },
    // 全局用到的一些方法
    // viewUserDetails(searchId) {
    //   this.$router.push({path: `/gpt/user/details/${searchId}`})
    // }

  },
  created() {
    const _that = this
    // console.log(`窗口初始化[app/create/${sessId}]成功！`)
    // clearInterval(sessId)


    // this.offcanvasVipWarning.visibleEnd = true
    this.wssBtnSts = this.wssBtnInfo.stopped

    this.$gkt.call('pushMsgHandler', 'navbar', msg => {
      // navbar的handler得在前面
      // const {type} = msg;
      // if (type === 13100 || type === 27 || type === 10004 || (type > 13000 && type < 13999)) {
      // console.log("---App--->", JSON.stringify(msg))
      // }

      LogMsgHandler.logMsgListener(msg, this)
      // that.globalMsgListener(msg)
      // 当有联系人更新，或是群列表更新时处理，另外将所有的会话消息分类存储
      SnsMsgHandler.contactsListener(msg, this)
      // 处理登录和退出
      SnsMsgHandler.snsOlineListener(msg, this)
      // 处理会话消息
      SnsMsgHandler.msgListener(msg, this)
      // 处理公众号自动回复的信息
      SnsMsgHandler.gzhAutoReply(msg, this)
      // 在线状态
      SnsMsgHandler.checkSnsOnlineStatus(msg, this)

    }).then(() => {
      if (window.ginkgoTo?.oemType && window.ginkgoTo?.oemType === 18) {
        this.isShow = false
      } else {
        this.isShow = false
      }

      // 自动创建GPT账号，并登录
      this.loginChatGPT(this.user)

      // DONE: window.ginkgoTo 使用时，必需判断，后期会加入remoteSns，可以使用 this.$gkt 替换
      console.log('在线状态检查事件注册...')
      this.$gkt.call('accountIsOnline', (info) => {
        if (info) {
          // 需要拿到信息-登录服务器
          let json = {phone: info.phone, password: "true", from: info.from};
          this.signIn(json)
        } else {
          _that.$router.push({name: 'login'})
        }
      }).then(() => {
        // 先成功注册消息事件，再将在线检查注册到消息事件里，这样离线登录才能生效
        console.log('检查登录状态...')
        this.bindSnsList.forEach(sns => {
          this.$gkt.executeSnsApi(this.uid, sns.snsId, sns.type, 'online', {})
        })
      }).catch(e => {
        console.log('e=>', e)
      })
    })
    // window.ginkgoTo.accountIsOnline()
    // signIn

    // 定时任务，发送...
    const timers = this.$dba.getItem('timers', {})
    let {taskTimerId} = timers
    if (taskTimerId) {
      clearInterval(taskTimerId)
    }
    taskTimerId = setInterval(() => {
      const tasks = this.$dba.getItem('tasklist_' + this.uid, [])
      // console.log('获取定时任务列表: ', tasks)
      const calendar = new Date()
      // console.log('当前时间2', )
      const currDate = {
        month: calendar.getMonth() + 1,
        week: calendar.getDay(),
        day: calendar.getDate(),
        hour: calendar.getHours(),
        minute: calendar.getMinutes()
      }

      const taskList = []
      for (const task of tasks) {
        let newTask = {...task}
        const {rooms, points, scheme} = task
        const {d, h, m, w, mm} = scheme
        let hit = h[currDate.hour - 1] && (d[currDate.day - 1] || w[currDate.week - 1])
        let mHit = false
        for (const e of m) { // 如果mHit为false，则说明没有指定月，这里则忽略它
          mHit = mHit || e
        }
        if (!mHit) { // 经过上面的循环，mHit为true说明设置过了，false，说明没有设置
          mHit = true // 没有设置过
        } else {
          mHit = m[currDate.month - 1] // 如果设置了月份，又是对应的，则是true
        }
        if (scheme.t) {
          hit = hit && (mm == currDate.minute)
        }
        // console.log(task.name + '计划任务hit', hit, mHit, scheme, task.loop)
        // !task?.isProcessed
        if (hit && mHit && task?.enabled
            && (!task.loop?.isMulti || !task.loop?.max || // 不是循环任务，或是不限制循环次数
                (task.loop?.isMulti && task.loop?.count < task.loop?.max))) {
          const _msg = task.msg
          const historyKey = this.savePromoteHistory(_msg, rooms, this.uid, points)
          const promoteH = this.$dba.getItem('promote_history_keys_' + this.uid, {})
          //
          if (historyKey && promoteH[historyKey]) {
            let index = 0
            let timeout = 0
            promoteH[historyKey].senders = {} // 记录发送账号
            promoteH[historyKey].type = {desc: '定时任务', icon: 'fi-clock'}

            if (this.user?.wallet?.balance && this.user?.wallet?.balance >= points.point) {
              for (const currRoom of task.rooms) {
                setTimeout(() => {
                  const senderId = currRoom.sourceId
                  // const contactId = idArr[1] + (idArr[2] ? '_'.concat(idArr[2]) : '')
                  const contactId = currRoom.roomId.substring(currRoom.sourceId?.length + 1)
                  // const currRoom = this.enabledRooms[index]

                  // 发消息
                  if (currRoom.snsType === 'EQQ') {
                    this.$gkt.executeSnsApi(this.uid, senderId, currRoom.snsType, 'sendMessage', {
                      contactId: contactId,
                      msg: _msg,
                      roomType: currRoom.roomType,
                      roomId: currRoom.payload?.group_id
                    })
                  } else {
                    this.$gkt.executeSnsApi(this.uid, senderId, currRoom.snsType, 'sendMessage', {
                      contactId: contactId,
                      msg: _msg,
                      roomType: currRoom.roomType,
                      roomId: 'buddy' === currRoom?.roomType ? currRoom.payload?.sender?.group_id : undefined
                    })
                  }

                  promoteH[historyKey].finished = ++index
                  promoteH[historyKey].senders[senderId] = {
                    snsId: senderId,
                    avatar: currRoom.avatar,
                    name: currRoom.name
                  }

                  currRoom.bg = 'text-success'
                  if (index === rooms.length) {
                    promoteH[historyKey].isFinished = true
                  }

                  // this.sendFinished = index
                  this.$dba.setItem('promote_history_keys_' + this.uid, promoteH)

                }, timeout)
                timeout += Math.floor(Math.random() * 10 + 1) * 1000
              }
            } else {
              promoteH[historyKey].type = {desc: '定时任务', icon: 'fi-clock text-danger'}
              promoteH[historyKey].errMsg = '余额不足'
              this.$dba.setItem('promote_history_keys_' + this.uid, promoteH)
            }

            this.$gkt.call('vipPay', points.point, (wallet) => {
              console.log('vipPay:', wallet?.data?.wallet)
              const _user = this.$store.getters.fetchUser(this.uid)
              if (_user) {
                this.$store.commit('upsertUser', {..._user, wallet: wallet?.data?.wallet})
              }
            })
          }

          newTask.loop.count = task.loop.count + 1
          newTask.isProcessed = true
          console.log('准备执行任务：', task.name, promoteH[historyKey])
        }

        taskList.push({...newTask})
      }
      if (taskList.length) {
        this.$dba.setItem('tasklist_' + this.uid, taskList)
      }


    }, 60000)

    this.$dba.setItem('timers', {taskTimerId})

    this.$dba.setItem('oemMenus', this.oemMenus)

    // window.ginkgoTo.onUpdateMsg()
    this.$gkt.call('onUpdateMsg', (event, arg) => {
      // console.log("app---> res-->", arg)
      switch (arg.state) {
        case 1:
          console.log('有更新....')
          this.$confirm('检测到有新版本,是否更新?', '版本更新', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            closeOnPressEscape: false,
          }).then(() => {
            this.dialogVisible = true;
            this.downloadUpdate()
          })
          break;
        case 4:
          console.log('下载完成')
          this.progressStatus = "success";
          // window.ginkgoTo.updateNow()
          this.$gkt.call('updateNow')
          break;
      }
    })

    // window.ginkgoTo.onDownLoadProgress()
    this.$gkt.call('onDownLoadProgress', (progressObj) => {
      console.log('更新下载进度:', progressObj.percent)
      this.percentage = progressObj.percent
    })


  },
  mounted() {
    this.$gkt.call('checkForUpdate')
    // window.ginkgoTo.checkForUpdate();
    this.$gkt.call('getVersionInfo', info => {
      console.log('version---===>>>', info)
      this.versionInfo = info
    })
    // window.ginkgoTo.getVersionInfo()
  },
  /* beforeUnmount() {
     this.ipcRenderer.removeAllListeners()
   }*/

}
</script>
<style lang="scss">

#app {
  font-family: "Microsoft YaHei UI", system-ui, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 5.4rem; /* 头部导航占位 */
  button {
    font-family: unset;
  }
}

.webp {
  // background: url("ginkgoto-res://slide1.webp") no-repeat center;
  // background: url("https://zhycit.com/slide1.webp") no-repeat center;
}

.ginkgoto-title-bar {
  position: absolute;
  top: 0;
  height: 1.8rem;
  width: 100%;
}

.ginkgoto-status-bar {
  font-size: 0.618rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  .ginkgoto-status-msg {
    //background-color: #2cd19c; // debug
  }
}

header {
  padding: 0 0.25rem !important;
}

.ginkgoto-navbar {
  border-bottom: 1px solid #64605e65;
  margin-top: 2rem !important;
  padding: 0 0 0.49rem;

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }
}

.ginkgoto-frame {
  height: calc(100vh - 6.8rem);
  overflow: hidden;
  //border: 1px solid blue;
  //background-color: blue;

  .ginkgoto-mask {
    z-index: 99999;
    width: 100%;
    height: 100%;

    margin: auto;

    h1 {
      opacity: 42.8%;
    }
  }

  .ginkgoto-view {
    width: 100%;
  }
}

.ginkgoto-status-bar {
  .sns-list-item {
    //border: 1px solid #FFFFFF45;
    border-radius: 50rem;
    //background-color: #64605e60;
    height: 0.9rem;
    min-width: 0.9rem;

    a {
      display: inline-grid;
    }

    .sns-card-info {
      .icon-box-media {
        min-width: 0.9rem;
        width: 0.9rem;

        .spinner-border {
          width: 1.1rem;
          height: 1.1rem;
        }
      }
    }
  }
}

.img-gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.ginkgoto-no-drag {
  -webkit-app-region: no-drag;
}

.ginkgoto-drag {
  -webkit-app-region: drag;

  button, a, input {
    -webkit-app-region: no-drag;
  }

  .dropdown {

  }
}

.z-menu {
  .nav-item {
    .z-auto-close {
      height: 100%;
      display: flex;
      align-items: center;

      img {
        margin-left: 0.95rem;
        margin-right: 0.35rem;
      }
    }
  }
}

.ginkgoto-auto-scroll {
  position: relative;
  height: 100%;

  .ginkgoto-scroll-content {
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    padding: 0 0 5rem;
  }
}

.z-avatar {
  display: inline-table;
  height: 2.8rem;
  width: 2.8rem;
  background-size: cover !important;
}

.z-avatar.small {
  height: 1.8rem;
  width: 1.8rem;
}
</style>
