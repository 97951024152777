<template>
  <div class="" style="font-size: 0.875rem;">
    <div class="d-flex mx-auto align-items-start border-bottom border-light px-3 py-1 mb-2"
         style="width: 16rem;">
      <div :class="'rounded-circle z-avatar ' + (sns.isOnline? '': 'img-gray')"
           :style="'background: url('+ sns.avatar +') center center no-repeat;'"
           :title="sns.name || '匿名用户'"></div>
      <div class="ps-2">
        <h6 class="fs-base text-light mb-0 me-2 d-flex align-items-center">
          <router-link v-if="isRouterLink" :to="{path: '/insider/chat', query: {snsId: sns.snsId}}"
                       class="text-truncate" style="max-width: 7.5rem;">
            {{ sns.name || '匿名用户' }}
          </router-link>
          <span v-else class="text-truncate" style="max-width: 7.5rem;">{{ sns.name || '匿名用户' }}</span>
          <i :class="sns.isOnline ? 'fi-man text-info' : 'fi-woman text-primary'"></i>
          <span style="font-size: 0.875rem; width: 3rem;"
                :class="sns.isOnline? 'text-success' : ' opacity-50'">({{ sns.isOnline ? '在线' : '离线' }})</span>
        </h6>
        <div class="fs-xs text-truncate text-uppercase font-monospace fw-bold opacity-50 pb-1" style="width: 10rem;"
             data-bs-toggle="tooltip" data-bs-placement="bottom" :title="sns.snsId">
          {{ sns.type }}:{{ sns.payload?.signature || sns.snsId }}
          <template v-if="sns.payload?.province || sns.payload?.city"><br>{{ sns.payload?.province }} -
            {{ sns.payload?.city }}
          </template>
        </div>
        <div class="d-flex mb-2">
          <button v-for="color of theme.colors" :key="color" type="button" @click="changeTheme(color)"
                  :style="'width: 0.8rem; height: 0.8rem;background-color:' + color"
                  class="border border-light me-1 btn btn-icon"></button>
          <!--            <i v-for="i in [0,1,2,3,4]" :key="i" class="star-rating-icon fi-star-filled active"></i>-->
        </div>
      </div>
    </div>
    <div v-if="'CHAT_GPT' !== sns?.type" class="d-flex justify-content-center pb-2 px-1">
      <a class="btn btn-xs btn-translucent-light me-1" @click="showModal = true"
         title="群">
        <i class="fi-users"></i></a>
      <a class="btn btn-xs btn-translucent-light me-1" @click="showFriendModal = true" title="好友">
        <i class="fi-friends"></i></a>

      <a class="btn btn-xs btn-translucent-success me-1" data-bs-toggle="modal"
         :data-bs-target="'#mdl_' + sns.snsId?.replace('@', '')"
         href="#" title="登录">
        <i class="fi-login"></i>登录</a>
      <a class="btn btn-xs btn-translucent-warning me-1" href="javascript:;" @click="logout" title="退出">
        <i class="fi-logout"></i>
      </a>
      <a class="btn btn-xs btn-translucent-danger" href="javascript:;" @click="unbindSns(sns.snsId)" title="删除">
        <i class="fi-trash"></i>
      </a>
      <Teleport to="main">
        <GroupModal :sns="sns" :show="showModal" @close="showModal = false"></GroupModal>
      </Teleport>
      <Teleport to="main">
        <FriendModal :sns="sns" :showFriend="showFriendModal" @closeFriend="showFriendModal = false"></FriendModal>
      </Teleport>
    </div>
    <div v-else class="d-flex justify-content-center pb-2 px-1">
      <router-link class="btn btn-xs btn-dark me-1 d-flex font-monospace fw-bold"
                   :to="{path: '/insider/chat', query: {snsId: sns.snsId}}" title="ChatGPT">
        <div class="me-2 "
             style="height: 1rem; width:1rem;background: url('https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/ChatGPT.png') no-repeat center; background-size: cover;"></div>
        体验 AI
      </router-link>
    </div>
  </div>

</template>

<script>
import GroupModal from "@/components/sns/models/GroupModal";
import FriendModal from "@/components/sns/models/FriendModal";

export default {
  name: "SnsSampleCard",
  components: {FriendModal, GroupModal},
  props: {
    sns: {
      type: Object,
      required: true
    },
    isRouterLink: {
      type: Boolean,
      required: false
    }
  },
  data: () => {
    return {
      uid: null,
      ginkgoTo: undefined,
      isGroup: true,
      showModal: false,
      showFriendModal: false,
      theme: {
        current: '#1f1b2d',
        // colors: ['#1f1b2d', '#2c3e50','#3DA579', '#B59A48', '#022B81', '#D8272D', '#D77476']
        colors: ['#1f1b2d', '#054F74', '#660C0C', '#098D68', '#404040',
          '#8F8863', '#823604', '#421241', '#236227']
      }
    }
  },
  methods: {
    changeTheme(color) {
      this.theme.current = color
      const sns = {...this.$store.getters.fetchSns(this.sns.snsId)}

      if (sns) {
        sns.theme = {
          bgColor: color
        }
        this.$store.commit('upsertSns', sns)
      }
    },
    unbindSns(snsId) {
      const a = confirm('确定要删除这个账号吗？如果删除账号，重启动应用后该账号将不可用！')
      if (a) {
        console.log('删除吧00')
        //先退出QQ，再删除
        this.logout()
        this.$store.commit('unbindSnsId', {userId: this.$root.uid, snsId})
        this.$store.commit('deleteSns', snsId)
        console.log('删除成功')
      } else {
        console.log('再想想')
      }
    },
    logout() {
      if (this.sns.type === 'MQ' && this.sns.isOnline) {
        let args = 'logout ' + this.sns.snsId
        window.ginkgoTo.invokeIpcMain('execMclCommand', null, args)
        this.$gkt?.executeSnsApi(this.$root.uid, this.sns.snsId, this.sns.type, 'logout', {})
      } else {
        this.$gkt?.executeSnsApi(this.$root.uid, this.sns.snsId, this.sns.type, 'logout', {})
      }
    }
  },
  created() {
    this.$gkt.call('pushMsgHandler', 'SnsInfoCard', msg => {
      const {type, objData} = msg
      if (27 === type) {
        console.log('SnsSampleCard收到的登录信息')
        try {
          bootstrap.Modal.getInstance('#mdl_' + objData.snsId?.replace('@', '')).hide()
        } catch (e) {
          //
        }
      }
    })
  }
}
</script>

<style scoped>
.z-avatar {
  display: inline-table;
  height: 2.8rem;
  width: 2.8rem;
  background-size: cover !important;
}
</style>
