const {v4: uuid} = require('uuid')

class LocalStorageSharding {
    constructor(pageSize, keyPrefix) {
        this.pageSize = parseInt(pageSize) || 10;
        this.keyPrefix = keyPrefix;
        this.totalPages = 0

        // 初始化时加载之前记录的页数
        const oldPages = localStorage.getItem(keyPrefix)
        if (oldPages) {
            this.totalPages = oldPages
        }
        this.pageOffset = this.totalPages
    }

    setItem(pageIndex, items) {
        if (!Array.isArray(items)) {
            throw new Error('Items must be an array');
        }
        const key = `${this.keyPrefix}_${pageIndex}`;
        localStorage.setItem(key, JSON.stringify(items));

        // if (pageIndex > this.totalPages) {}
    }

    getItem(pageIndex) {
        const key = `${this.keyPrefix}_${pageIndex}`;
        const items = JSON.parse(localStorage.getItem(key)) || [];
        return items;
    }

    setAllItems(items) {
        if (!Array.isArray(items)) {
            throw new Error('Items must be an array');
        }

        this.totalPages = Math.ceil(items.length / this.pageSize);
        localStorage.setItem(this.keyPrefix, this.totalPages)
        let startIndex = 0;
        let endIndex = this.pageSize;
        for (let i = 0; i < this.totalPages; i++) {
            const pageItems = items.slice(startIndex, endIndex);
            this.setItem(i + 1, pageItems);
            startIndex += this.pageSize;
            endIndex += this.pageSize;
            console.log('初始化分片数据：' + this.keyPrefix + '_' + (i+1) + ' '
                + `${startIndex}~${endIndex} `
                + pageItems.length + '/' + this.pageSize)
        }
    }

    getAllItems() {
        const items = [];
        for (let i = 0; i < this.totalPages; i++) {
            const pageItems = this.getItem(i + 1);
            items.push(...pageItems);
        }
        return items;
    }

    tailPages(num) {
        const ret = [] // this.getItem()
        const _num = num || 1
        if (this.totalPages) {
            for (let i = this.totalPages - _num + 1; i <= this.totalPages; i++) {
                // console.log('tail pages: ' + i)
                ret.push(...this.getItem(i))
                this.pageOffset--
            }
        }
        return ret
    }

    prevPages(num) {
        const ret = []
        const _num = num || 1
        if (0 < (this.pageOffset)) {
            for (let i = 0; i < _num; i++) {
                ret.push(...this.getItem(this.pageOffset))
                console.log('prePage: ' + this.pageOffset + '/' + this.totalPages + ': -' + num)
                this.pageOffset -= 1
            }
        }
        return ret
    }

    addItems(newItems) {
        if (!Array.isArray(newItems)) {
            throw new Error('New items must be an array');
        }
        let currentPage = parseInt(this.totalPages + '');
        if (currentPage === 0) {
            this.setAllItems(newItems);
            return;
        }
        let lastPageItems = this.getItem(currentPage);
        const totalItems = [...lastPageItems, ...newItems];
        // FIXME: GPT生成也有bug！ 这个totalPageCount是当前数据可以分为两页
        const totalPageCount = Math.ceil(totalItems.length / this.pageSize);
        // 当当前页数大于2时，以下就不对了 gpt生成的是if (totalPageCount === currentPage)
        if (totalPageCount === 1) {
            this.setItem(currentPage, totalItems);
        } else {
            // 直接把最后一页给加上！
            const lastPageSize = totalItems.length % this.pageSize;
            const lastPage = totalItems.slice(-lastPageSize);
            const lastPageIndex = currentPage + totalPageCount - 1
            this.setItem(lastPageIndex, lastPage);

            // 如果一次添加的数据量太大，分片存储到本地
            for (let i = currentPage + 1; i < lastPageIndex; i++) {
                const startIndex = (i) * this.pageSize;
                const endIndex = startIndex + this.pageSize;
                const pageItems = totalItems.slice(startIndex, endIndex);
                this.setItem(i, pageItems);
            }
            this.totalPages = lastPageIndex;
        }
        localStorage.setItem(this.keyPrefix, this.totalPages)
    }

    searchItems(query) {
        const items = []
        for (let i = 0; i < this.totalPages && i < 20; i++) {
            const pageItems = this.getItem(i + 1);
            items.push(...(pageItems.filter(e => {
                // console.log('search:', e.content)
                return e.content?.indexOf(query) >= 0
            })));
        }
        return items
    }

    delItemByUUID(uuid) {
        if (uuid) {
            for (let i = 0; i < this.totalPages && i < 20; i++) {
                const pageItems = this.getItem(i + 1);
                const _newItems = pageItems.filter(e => {
                    // console.log('search:', e.content)
                    return e.uuid !== uuid
                })
                if (_newItems?.length < pageItems.length) {
                    this.setItem(i + 1, _newItems)
                    break
                }
            }
        }
    }

    updateItemByUUID(uuid, item) {
        console.log('准备更新uuid', uuid)
        if (uuid && item) {
            for (let i = 0; i < this.totalPages; i++) {
                const pageItems = this.getItem(i + 1)
                let updateIndex = -1
                for (let j = 0; j < pageItems.length; j++) {
                    if (pageItems[j].uuid === uuid) {
                        updateIndex = j
                        break
                    }
                }
                if (updateIndex >= 0) {
                    console.log('更新了item', uuid)
                    pageItems[updateIndex] = {...item}
                    this.setItem(i + 1, pageItems)
                    break
                }
            }
        }
    }

    repairShardingItems() {
        const items = []
        for (let i = 0; i < this.totalPages && i < 20; i++) {
            const pageItems = this.getItem(i + 1)
            for (let j = 0; j < pageItems.length; j++) {
                let _item = pageItems[j]
                if (!_item.uuid) {
                    _item.uuid = uuid()
                }
                items.push(_item)
            }
            console.log('恢复分片数据：' + (i + 1) + '/' + this.totalPages + ' [' + items.length + ']')
        }
        this.setAllItems(items)
    }

    dropSharding() {
        for (let i = 0; i < this.totalPages && i < 20; i++) {
            const key = `${this.keyPrefix}_${i}`
            localStorage.removeItem(key)
        }
        localStorage.removeItem(this.keyPrefix)
    }
}

module.exports = LocalStorageSharding
