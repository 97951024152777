export default {
    namespaces: true,
    state: {
        userMap: {},
        currentUid: undefined,
        dontShowNoticeAgain: false
    },
    getters: {
        onLogin: state => userId => {
            if (userId) {
                const user = state.userMap[userId + '']
                if (user['isOnLogin']) {
                    return true
                }
            }
            return false
        },
        userList: state => {
            let userList = []
            for (const key in state.userMap) {
                userList.push(state.userMap[key])
            }
            return userList
        },
        fetchUser: state => userId => {
            if (state.userMap) {
                // console.log('hahahah-----------' , state.userMap)
                return state.userMap[userId + '']
            }
        },
        currentUid: state => () => {
            return state.currentUid
        },
        currentBindSnsKeys: state => (uid) => {
            let _bindSnsKeys = []
            const userId = uid || state.currentUid
            if (userId && state.userMap) {
                const user = state.userMap[userId]
                if (user?.bindSnsMap) {
                    for (const key in user.bindSnsMap) {
                        _bindSnsKeys.push(key)
                    }
                    return _bindSnsKeys
                }
            }
            return _bindSnsKeys;
        },
        dontShowNoticeAgain: state => () => {
            return state.dontShowNoticeAgain
        }
    },
    mutations: {
        upsertUser(state, user) {
            // console.log('update user:', user)
            if (user) {
                const {userId} = user
                if (userId) {
                    const _userIdStr = userId + ''
                    let ret = {bindSnsMap: {}}
                    const oldUser = state.userMap[_userIdStr]
                    if (oldUser) {
                        ret = {...oldUser}
                    }
                    ret = {...ret, ...user}
                    state.userMap[_userIdStr] = ret
                }
            }
        },
        bindSnsId(state, payload) {
            // console.log('bindSnsId', payload)
            const {userId, snsId} = payload
            if (userId && snsId) {
                const _userIdStr = userId + ''
                const _snsIdStr = snsId + ''
                let user = state.userMap[_userIdStr]
                if (!user) {
                    user = {userId, bindSnsMap: {}}
                }
                user.bindSnsMap[_snsIdStr] = new Date().getTime()
                state.userMap[_userIdStr] = user
            }
        },
        unbindSnsId(state, payload) {
            console.log('unbind snsId', payload)
            const {userId, snsId} = payload
            if (userId && snsId) {
                const _userIdStr = userId + ''
                const _snsIdStr = snsId + ''
                let user = state.userMap[_userIdStr]
                console.log('debug unbind snsId', user)
                if (user) {

                    user.bindSnsMap[_snsIdStr] = undefined
                    state.userMap[_userIdStr] = user
                }
            }
        },
        unbindAllSns(state, payload) {
            const {userId} = payload
            if (userId) {
                const _userIdStr = userId + ''
                let user = state.userMap[_userIdStr]
                if (user) {
                    user.bindSnsMap = {}
                    state.userMap[_userIdStr] = {...user}
                }
            }
        },
        setCurrentUid(state, payload) {
            // console.log('setCurrentUid:', payload)
            state.currentUid = payload?.userId
        },
        setDontShowNoticeAgain(state, isShow) {
            state.dontShowNoticeAgain = isShow
        }
    },
    actions: {}
}
