import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import account from './modules/account'
import sns from './modules/sns'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    account,
    sns
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : [createPersistedState()]
  plugins: [createPersistedState()]
})
