import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from "@/utils/plugins";
import VueCookie from 'vue-cookies'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueClipboard from 'vue3-clipboard'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store)
    .use(router)
    .use(plugins)
    .use(VueCookie)
    .use(ElementPlus)
    .use(VueLazyLoad)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    }).mount('#app')
