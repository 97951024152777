const GinkgoToApi = {
    // ginkgoTo: window.ginkgoTo,
    tryGinkgoToCount: 100,
    tempIds: [],
    _invoke(method, args) {
        if ('function' === typeof window.ginkgoTo?.invoke) {
            window.ginkgoTo['invoke'](method, args)
        }
    },
    executeSnsApi(uid, snsId, snsType, method, params) {

        // if ('function' === typeof this._invoke) {
        //     return this._invoke('executeSnsApi', {uid, method, snsId, snsType, args: params})
        // }
        this.call('invoke', 'executeSnsApi', {
            uid, method, snsId, snsType, args: params
        }).then(() => {/**/})
    },
    executeAccountApi(uid, method, originInfo, args) {
        // console.log(`${uid} executeAccountApi:${method}/>`, originInfo, args)
        // if ('function' === typeof this._invoke) {
        //     return this._invoke('executeAccountApi', {uid, method, originInfo, args})
        // }
        this.call('invoke', 'executeAccountApi', {
            uid, method, originInfo, args
        })
    },
    executePuppetApi(uid, snsId, snsType, method, params) {
        if ('function' === typeof this._invoke) {
            // console.log('是这里调用了吗？', params)
            return this._invoke('executePuppetApi', {uid, method, snsId, snsType, args: params})
        }
    },
    fetchSnsDesc() {
        if (window.ginkgoTo) {
            const _snsDesc = window.ginkgoTo['SnsDesc']
            if (_snsDesc) {
                return Object.values(_snsDesc)
            }
        }
        return []
    },
    call(api, ...params) {
        return new Promise(((resolve) => {
            // console.log(`call ${api}, ${params? params[0] : ''} time id is ${this.tempIds.length}`)
            if (!window.ginkgoTo) {
                let _count = GinkgoToApi.tryGinkgoToCount
                this.tempIds.push(setInterval(() => {
                    if (window.ginkgoTo) {
                        for (const tmpId of this.tempIds)
                            clearInterval(tmpId)
                        this._call(api, ...params)
                        resolve()
                    } else {
                        if (0 >= _count--) {
                            for (const tmpId of this.tempIds)
                                clearInterval(tmpId)
                        }
                    }
                    // console.log(`calling ${api}, ${params? params[0] : ''} time id is ${this.tempIds.length}`)
                }, 100))
            } else {
                this._call(api, ...params)
                resolve()
            }
        })).catch(reason => {
            console.error(reason)
        })
    },
    _call(api, ...params) {
        if (window.ginkgoTo && 'function' === typeof window.ginkgoTo[api]) {
            // console.log(`called ${api}`)
            return window.ginkgoTo[api](...params)
        }
    }
}
module.exports = GinkgoToApi
