<template>
  <div class="dropdown d-none d-lg-block sns-card-info ">
    <button class="btn btn-dark d-flex rounded-pill p-0 align-items-center" :id="'card-' + user?.userId"
            data-bs-toggle="dropdown" aria-expanded="false">
      <span class="text-secondary d-block text-truncate" style="max-width: 5rem;">{{ user.userName }}</span>
      <div v-if="user.userImage"
           :class="'icon-box-media bg-faded-accent rounded-circle ms-2 ' + (user.isOnline ? '': 'img-gray')"
           :style="'background-image: url('+user.userImage+');width:2rem;height:2rem;'"></div>
      <div v-else class="icon-box-media bg-faded-accent text-primary rounded-circle me-2">
        <i class="fi-wechat"></i>
      </div>
    </button>
    <div class="dropdown-menu dropdown-menu-dark dropdown-menu-end pt-0" :aria-labelledby="'card-' + user.userId">
      <div class="d-none align-items-start border-bottom border-light px-3 py-1 mb-2" style="width: 16rem;">
        <img :class="'rounded-circle ' + (user.isOnline? '': 'img-gray')" :src="user.userImage"
             :alt="user.userName || '匿名用户'" style="height: 3rem;width: 3rem;">
        <div class="ps-2">
          <div class="d-flex align-items-baseline">
            <h6 class="text-wrap fs-base text-light mb-0 me-2 d-flex align-items-center text-truncate">
              {{ user.userName || '匿名用户' }}</h6>
            <small class="text-primary small font-monospace" v-show="$parent.vipInfo?.isVip">VIP</small>
          </div>
          <div class="fs-xs py-0">{{ user.phone }}<br/>
            <div class="text-wrap">
              {{ user.companyName }}
              <span v-if="user.shopName"><br><i class="fi-shop me-2"></i> {{ user.shopName }}</span>
            </div>
            <!-- vip info -->
            <div class="card p-1 card-light bg-none rounded-0">
              <div v-if="$parent.vipInfo?.isVip" class="font-monospace opacity-80 pb-2">
                {{ $parent.vipInfo?.vipDesc }}
              </div>
              <div class=" text-warning d-flex align-items-center justify-content-between">
                <div class="d-flex font-monospace align-items-center">
                  <img class="" style="height: 2rem"
                       src="https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/logo-coin-s.png">
                  <span class="h4 text-warning m-0 p-0">{{ user.wallet?.balance || '0' }}</span></div>
                <button type="button" class="btn btn-xs py-0 px-1 ms-1 btn-warning btn-outline-warning"
                        id="user-info-pay"
                        data-bs-toggle="modal" data-bs-target="#mdlPay">充值
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <user-base-info-card class="border-bottom border-light card-light pt-2 mt-0 px-2 pb-3"></user-base-info-card>
      <!--      <router-link class="dropdown-item" v-if="hasOemType" to="/personalCenter" :user="user"><i
                class="fi-user me-2"></i>个人中心
            </router-link>
            <router-link class="dropdown-item" v-else
                         :to="{name: 'iframe', query: {src: '/qidian-server/ginkgo/page/ginkgoHome'}}">
              <i class="fi-user me-2"></i>个人中心
            </router-link>-->
      <a class="dropdown-item" href="javascript:;" @click="viewPersonalCenter"><i class="fi-user me-2">个人中心</i></a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:;" @click="viewOrderList"> <i class="fi-cart me-2"></i>订单列表</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#mdlPayCode"><i
          class="me-2 fi-paypal"></i>VIP兑换码</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" @click="$parent.invalidateCaches()">
        <div class="d-flex align-content-start">
          <div><i class="fi-trash me-2"></i></div>
          <span class="">清理本机缓存<br/>Invalidate Caches ...</span>
        </div>
      </a>
      <div class="dropdown-divider"></div>
      <div class="dropdown-item d-flex align-items-center justify-content-around">
        <a class="btn btn-xs btn-translucent-light me-1" @click="power" data-bs-toggle="tooltip" data-bs-placement="top"
           title="重启服务"><i class="fi-refresh me-2"></i>重启</a>
        <a class="btn btn-xs btn-translucent-light me-1" @click="changeAccount" data-bs-toggle="tooltip"
           data-bs-placement="top" title="切换账号"><i class="me-2 fi-switch-horizontal"></i>切换</a>
        <a class="btn btn-xs btn-translucent-light me-1" @click="logoutAccount" data-bs-toggle="tooltip"
           data-bs-placement="top" title="退出登录"><i class="me-2 fi-logout"></i>退出</a>
      </div>
    </div>
  </div>

</template>
<script>

import UserBaseInfoCard from "@/components/sns/UserBaseInfoCard";
export default {
  components: {UserBaseInfoCard},
  props: {
    user: {
      type: Object,
      required: true
    },
    power: {
      type: Function,
      required: true
    },
    stop: {
      type: Function,
      required: true
    }
  },

  data: () => {
    return {
      // ginkgoTo: undefined
      hasOemType: false
    }
  },
  /*created() {
    if (window.ginkgoTo.oemType && window.ginkgoTo.oemType === 18) {
      this.hasOemType = true
    } else {
      this.hasOemType = false
    }
  },*/
  computed: {},
  methods: {
    viewOrderList() {
      try {
        window.ginkgoTo.getHttpRequest('/bis/zq/search?_s=shbqq&_from=epc', {_s: 'shbqq', _from: 'epc'}, () => {
        })
      } catch (e) {
        //
      }
      this.$router.push({name: 'iframe', query: {src: '/qidian-server/ginkgo/page/recharge'}})
    },
    viewPersonalCenter() {
      if (window.ginkgoTo?.oemType === 18) {
        this.$router.push({path: '/personalCenter', query: {userInfo: JSON.stringify(this.user)}})
      } else {
        this.$router.push({name: 'iframe', query: {src: '/qidian-server/ginkgo/page/ginkgoHome'}})
      }
    },
    //切换账户
    changeAccount() {
      window.ginkgoTo.changeAccount(isOK => {
        if (isOK) {
          this.$dba.removeItem("upsertUser")
          this.$dba.removeItem("setCurrentUid")
          this.$parent.updateUser();
        }
        this.$router.push({name: "login"})
      })
    },
    logoutAccount() {
      const that = this
      window.ginkgoTo.logoutAccount(user => {
        console.log('logout:', user)
        if (user) {
          this.$parent.stop()
          const _user = that.$store.getters.fetchUser(user.userId)
          const updateUser = {
            ..._user,
            isOnline: false
          }

          that.$store.commit('upsertUser', updateUser)
          that.$store.commit('setCurrentUid', null)
          this.$router.push({name: 'login'})
        }
      })
    },

    //启动企业QQ服务
    startProgram() {
      window.ginkgoTo.stopProgram(res => {
        console.log("res==>", res)
      })
    }
  },
  mounted() {
    // this.ginkgoTo = window.ginkgoTo
    // console.log('$parent:....', this.$parent.vipInfo)
  }
}
</script>
<style lang="scss" scoped>
.sns-card-info {
  z-index: 99;

  .icon-box-media {
    height: 1.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    font-size: 0.857rem;
    line-height: 1.35rem;
  }
}
</style>
