/**
 * LogHandler
 *
 * 注入页面：App
 *
 * 日志消息的相关处理器
 */
class LogMsgHandler {
    /**
     * 日志，将所有的消息按消息类型保存的日志中，每类型最多存1000条
     */
    static logMsgListener(msg, view) {
        const that = view

        that.wssBtnSts = that.wssBtnInfo.started
        that.powerBtnLoading = false
        try {
            // 有消息，说明WSS线程是开着的
            that.autoStartSns(msg, view)

            const {objData, type} = msg
            const {methodName} = objData

            that.msgSpinner = true
            that.msgIcon = that.msgTypeIcons['' + type] || that.msgDefaultIcon
            if (that.msgSpinnerTID > 0) {
                clearTimeout(that.msgSpinnerTID)
            }
            that.msgSpinnerTID = setTimeout(() => {
                that.msgSpinner = false
            }, 1000)

            // that.msgType = type
            that.wssStatus = type
            if ('stop' === methodName) {
                console.log('Ginkgoto Service was stopped')
                that.wssBtnSts = that.wssBtnInfo.stopped
            }

            // 存放消息日志
            // const key = 'log_' + type // 缓存key

            // let msgQueue = []
            // if (localStorage[key]) {
            //   const _str = localStorage[key]
            //   try {
            //     msgQueue = JSON.parse(_str)
            //   } catch (e) {/* */
            //   }
            // }
            // if (that.cacheQueueLength <= msgQueue.length) {
            //   msgQueue.splice(0, msgQueue.length - that.cacheQueueLength + 1)
            // }
            // msgQueue.push(msg)

            // let logKeys = {} // 用户来记录日志对应的缓存key
            // if (localStorage?.logKeys) {
            //   logKeys = that.$dba.getItem('logKeys')
            // }
            // logKeys[key] = msgQueue.length

            that.$dba.log(msg)
            // that.$dba.setItem(key, msgQueue)
            // that.$dba.setItem('logKeys', logKeys)
            // localStorage.setItem(key, JSON.stringify(msgQueue))
            // localStorage.setItem('logKeys', JSON.stringify(logKeys))

        } catch (e) {
            console.error(e)
        }
    }

    static mclHandleMap = new Map()

    static currentSnsId = null

    static mclMsgHandle(msg) {
        LogMsgHandler.mclHandleMap.forEach(item => {
            const {snsId, handle, view} = item
            if ('function' === typeof handle && snsId === LogMsgHandler.currentSnsId) {
                handle(msg, view, snsId)
            }
        })
    }

    static addMsgHandle(_snsId, view, handle) {
        LogMsgHandler.mclHandleMap.set(_snsId, {
            snsId: _snsId, view, handle: (msg, view, snsId) => {
                handle(msg, view, snsId)
            }
        })
    }


}

module.exports = LogMsgHandler
