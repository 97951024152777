<template>
  <div class="d-flex d-md-block d-lg-flex align-items-start mb-4" style="max-width: 21rem;min-width: 15rem;">
    <div class="rounded-circle z-avatar"
         :style="'background: url(\'' + ($root.user?.userImage || 'https://zhycit.com/templates/finder/img/avatars/01.jpg') + '\') no-repeat center;'">

    </div>
    <div class="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3" style="">
      <h2 class="text-wrap fs-base text-light mb-0 me-2 d-flex align-items-center text-truncate">{{ $root.user?.userName || 'Robert Fox' }}</h2>
      <button type="button" class="btn btn-xs btn-translucent-warning px-0 py-0 fw-bold d-flex font-monospace align-items-center small mt-1"
              id="user-info-pay"
              data-bs-toggle="modal" data-bs-target="#mdlPay">
        <img class="me-1" style="height: 1.3rem"
             src="https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/logo-coin-s.png">{{ $root.user.wallet?.balance || '0' }}
        <span  class=" px-1 ms-1 small">(<small class="small font-monospace" v-show="$root.vipInfo?.isVip">VIP</small>充值)</span>
      </button>
      <div v-if="$root.vipInfo?.isVip" class="font-monospace opacity-50 fs-xs fw-bold">
        {{ $root.vipInfo?.vipDesc }}
      </div>
      <ul class="list-unstyled fs-sm mt-3 mb-0">
        <li><a class="nav-link-light fw-normal" href="javascript:;"><i class="fi-phone opacity-60 me-2"></i>{{ $root.user?.phone || '(302) 555-0107' }}</a></li>
        <li><a class="nav-link-light fw-normal d-flex align-items-start" href="javascript:;"><i class="fi-mail opacity-60 me-2 mt-1"></i>
          <span class="text-wrap">{{$root.user?.companyName || ' - '}}</span></a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserBaseInfoCard"
}
</script>

<style scoped>
.z-avatar {
  display: inline-table;
  height: 2.8rem;
  width: 2.8rem;
  background-size: cover !important;
}
</style>
