<template>
  <div class="card-active m-auto ginkgoto-sns-bind">

    <div v-if="loading" class="page-loading active"
         style="width:16%;height:24%;top: 36%;left: 42%;background-color: #ffffff">
      <div class="page-loading-inner">
        <div class="page-spinner"></div>
        <div>拼命加载中...</div>
      </div>
    </div>
    <div v-else>
      <div v-if="isReadyOK">
        <div class="card-body p-1">
          <div id="snsLoginFrame" :class="' m-auto text-center'" style="height: 248px">
            <el-image :class="(isOnline?'': 'img-gray')" :src="this.loginBgImg"
                      :style="'width: 15rem; height: 15rem; overflow: hidden; no-repeat center; background-size: cover;'"></el-image>
          </div>
        </div>
        <div class="card-footer p-1 px-2 border-0">
          <input class="form-control form-control-light" type="text" placeholder="请输入账号" v-model="snsId">
          <div class="password-toggle">
            <input class="form-control form-control-light mt-2" :type="!showPwd? 'password' : 'text'"
                   id="account-password" v-model="snsPwd"
                   placeholder="请输入密码" required>
            <label class="password-toggle-btn" aria-label="Show/hide password">
              <input class="password-toggle-check" type="checkbox" v-model="showPwd"><span
                class="password-toggle-indicator"></span>
            </label>
          </div>

          <div class="border-light text-center pt-4 pb-3">
            <button v-show="!powerBtnLoading" type="button" class="btn btn-outline-primary btn-sm" style="width: 8.5rem"
                    v-on:click="loginQQ">
              <i :class="powerBtnSts.icon + ' me-2'"></i>{{ powerBtnSts.text }}
            </button>
            <button v-show="powerBtnLoading" type="button" class="btn btn-secondary btn-sm" style="width: 8.5rem">
              <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              Loading...
            </button>
            <button type="button" class="btn btn-outline-light btn-sm ms-2" style="width: 8.5rem"
                    data-bs-dismiss="modal" aria-label="Close">关闭
            </button>
          </div>
        </div>
      </div>
      <div v-else>

        <div class="card-body text-center p-1 pt-5" style="height: 438px">
          <div>
            <button class="btn btn-outline-primary btn-sm" style="width: 8.5rem" @click="oneRegisterService">
              一键注册服务
            </button>
          </div>

          <!--        <div>
                    <div class="link-light fst-normal">第一步:下载文件</div>
                    <loading-card :is-done="isDownload" :btn-click="downLoadZip"
                                  :btn-text="{isOK:'文件已下载' , noOK:'下载文件'}"></loading-card>
                  </div>
                  <div class="mt-3">
                    <div class="link-light fst-normal">第二步:解压文件</div>
                    <loading-card :is-done="isUnzip" :btn-click="UnZIP"
                                  :btn-text="{isOK:'文件已解压' , noOK:'解压文件'}"></loading-card>
                  </div>
                  <div class="mt-3">
                    <div class="link-light fst-normal">第三步:注册服务</div>
                    <loading-card :is-done="isRegister" :btn-click="registerService"
                                  :btn-text="{isOK:'服务已注册' , noOK:'注册服务'}"></loading-card>
                  </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: "EqqLogin",
  props: {
    uid: {
      type: String,
      required: true
    },
    sns: {
      type: Object,
      required: false,
    }
  },
  data: () => {

    return {
      snsId: '',
      snsPwd: '',
      showPwd: false,
      isOnline: false,
      loginBgImg: 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/tongxun/eqq.png',
      powerBtnSts: {},
      powerBtnInfo: {
        started: {icon: 'fi-power', text: '退出', btn: 'btn-primary', status: true},
        stopped: {icon: 'fi-play', text: '登录', btn: 'btn-secondary', status: false}
      },
      powerBtnLoading: false,
      isReadyOK: false,
      isDownload: false,
      isUnzip: false,
      isRegister: false,
      isStart: false,
      loading: false
    }
  },
  methods: {
    initMsgHandler(currentSnsId) {
      if ('function' === typeof window.ginkgoTo['pushMsgHandler']) {
        console.log('Eqq开启登录事件监听---', currentSnsId)
        const that = this
        window.ginkgoTo['pushMsgHandler']('EqqLogin', msg => {
          that.snsLoginListener(msg, currentSnsId)
        })
      }
    },
    snsLoginListener(msg, currentSnsId) {
      try {
        const {objData, type} = msg
        if (objData) {
          const {payload} = objData
          // console.log("currentSnsId::", currentSnsId)
          if (type === 13100 && currentSnsId === objData.snsId) {
            console.log("---Eqq--->ObjData:", objData)
            console.log("登录成功")
            this.loginBgImg = payload?.avatar
            this.powerBtnLoading = false
            this.powerBtnSts = this.powerBtnInfo.started
            this.isOnline = true
            // this.getQQGroupAndFriendList()
          }
          if (type === 13010 && currentSnsId === objData.snsId) {
            this.powerBtnLoading = false
            this.powerBtnSts = this.powerBtnInfo.stopped
            this.isOnline = false
            return ElMessage({
              message: "账号已退出",
              type: 'success'
            })
          }
          //登录失败
          if (type === 13011 && currentSnsId === objData.snsId) {
            this.powerBtnLoading = false
            this.powerBtnSts = this.powerBtnInfo.stopped
            this.isOnline = false
            return ElMessage({
              message: objData?.msg,
              type: 'fail'
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    start() {
      console.log('eqqLogin is setup...', this.$store.getters.fetchSns(this.snsId))
      if (!this.snsId || !this.snsPwd) {
        return ElMessage({message: "QQ账号,密码不能为空", type: 'warning'})
      }
      if (!this.isOnline && this.gkt) { // 用gkt来判断是不是electron的终端
        this.initMsgHandler(this.snsId);
        this.gktExecuteSnsApi({
          uid: this.uid,
          method: 'login',
          snsType: 'EQQ',
          snsId: this.snsId,
          args: {uid: this.uid, snsId: this.snsId, account: this.snsId, token: this.snsPwd, pwd: this.snsPwd}
        })
      }
    },
    stop() {
      if (this.gkt) {
        this.gktExecuteSnsApi({uid: this.uid, method: 'logout', snsType: 'EQQ', snsId: this.snsId, args: {}})
      }
    },
    loginQQ() {
      if (this.snsId) {
        this.powerBtnLoading = true
        if (this.isOnline) {
          this.stop()
        } else {
          this.start()
        }
      }
    },
    getQQGroupAndFriendList() {
      this.gktExecuteSnsApi({
        uid: this.uid,
        method: 'getContactRooms',
        snsType: 'EQQ',
        snsId: this.snsId,
        args: {uid: this.uid, account: this.snsId, token: this.snsPwd}
      })
    },

    downLoadZip(cb) {
      console.log('downLoadZip')
      window.ginkgoTo.getUserDataPath().then(path => {
        if (path) {
          window.ginkgoTo.downLoadZip(path).then(res => {
            console.log("res==>", res)
            this.isDownload = res;
            if (typeof cb === 'function') {
              cb('downLoadZip')
            }
          })
        }
      })
    },
    UnZIP(cb) {
      window.ginkgoTo.getUserDataPath().then(path => {
        window.ginkgoTo.startUnzip(path, res => {
          this.isUnzip = res;
          console.log("isUnzip:", res)
          if (typeof cb === 'function')
            cb('unZip')
        });
      })
    },
    registerService(cb) {
      window.ginkgoTo.getUserDataPath().then(path => {
        window.ginkgoTo.registerServer(path, res => {
          this.isRegister = res
          console.log("registerServer: ", res)
          if (typeof cb === 'function') {
            cb('register')
          }
        })
      })

    },

    downLoadIsOK() {
      window.ginkgoTo.getUserDataPath().then(path => {
        window.ginkgoTo.zipIsDownLoad(path, (res) => {
          this.isDownload = res
        })
      })

    },
    unZipIsOK() {
      window.ginkgoTo.getUserDataPath().then(path => {
        window.ginkgoTo.unzipIsOK(path, (res) => {
          this.isUnzip = res;
        })
      })

    },
    isRegisterOK() {
      setTimeout(() => {
        window.ginkgoTo.programIsStart((res) => {
          // console.log('----res111---', res)
          // this.isRegister = res;
          if (res) {
            this.isReadyOK = true;
          } else {
            this.isReadyOK = false;
            setTimeout(() => {
              window.ginkgoTo.serversIsRegister((res) => {
                console.log('----res66---', res)
                this.isRegister = res;
                if (res) {
                  this.isReadyOK = true;
                }
              })
            }, 5000)
          }
        }, 10000)
      })
    },
    oneRegisterService() {
      this.loading = true
      window.ginkgoTo.getUserDataPath().then(path => {
        // console.log('path--->', path)
        window.ginkgoTo.downLoadEqqZip(path).then(result => {
          if (result) {
            window.ginkgoTo.unZip(path, result).then(val => {
              console.log('val-->', val);
              if (val) {
                //mac测试用this.loading = false
                window.ginkgoTo.registerEqqService(val).then(res => {
                  console.log('res--》', res)
                  if (res) {
                    setTimeout(() => {
                      this.loading = false
                      window.ginkgoTo.startEqqProgram().then(value => {
                        this.isReadyOK = value;
                      }).catch(e => {
                        this.isReadyOK = false;
                        console.error('e-->', e.message)
                      })
                    }, 10000)
                  }
                })
              }
            }).catch(e => {
              this.loading = false;
              console.error('e-->', e)
              return ElMessage({message: "注册服务失败,请重新注册", type: 'error'})
            })
          }
        })
      })
    }
  },
  watch: {
    isReadyOK: 'isRegisterOK'
  },
  created() {
    this.powerBtnSts = this.powerBtnInfo.stopped
    if (this.sns) {
      this.snsId = this.sns.snsId
      this.snsPwd = this.sns.snsPwd || this.sns.pwd
      this.loginBgImg = this.sns.avatar
    }
    //文件是否下载
    /*window.ginkgoTo.getUserDataPath().then(val => {
      window.ginkgoTo.zipIsDownLoad(val, (res) => {
        this.isDownload = res
      })
      //文件是否解压
      window.ginkgoTo.unzipIsOK(val, (res) => {
        this.isUnzip = res;
      })
      //服务是否注册
      window.ginkgoTo.serversIsRegister((res) => {
        this.isRegister = res;
        if (this.isRegister) {
          console.log("isReadyOK")
          this.isReadyOK = true;
        } else {
          this.isReadyOK = false;
        }
      })
    })*/

    //程序是否启动
   /* window.ginkgoTo.programIsStart((res) => {
      this.isStart = res;
      console.log('isStart-->', this.isStart)
      if (this.isStart) {
        console.log("isReadyOK")
        this.isReadyOK = true;
      } else {
        this.isReadyOK = false;
      }
    })*/
  },
  setup() {
    const gkt = window.ginkgoTo
    const store = useStore()

    function gktExecuteSnsApi(params) {
      const EXE_SNS_API = 'executeSnsApi'
      console.log('gktExecuteSnsApi:', params)
      if ('function' === typeof gkt['invoke']) {
        gkt['invoke'](EXE_SNS_API, params)
      }
    }

    return {
      gkt,
      store,
      gktExecuteSnsApi
    }
  }
}
</script>

<style scoped>
.ginkgoto-sns-bind {
  width: 23rem;
  height: 30rem;
}
</style>
