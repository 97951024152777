<template>
  <div class="card-active  m-auto ginkgoto-sns-bind">
    <div v-if="loading" class="page-loading active"
         style="width:16%;height:24%;top: 36%;left: 42%;background-color: #ffffff">
      <div class="page-loading-inner">
        <div class="page-spinner"></div>
        <div>MQ程序启动中...</div>
      </div>
    </div>
    <div v-else>
      <div class="card-body p-1">
        <div class="my-2 m-auto text-center rounded" style="height: 22.3rem">
          <div id="loginFrame"
               :class="'my-2 m-auto text-center rounded border-secondary '"
               :style="'width: 6rem; height: 6rem; overflow: hidden;background:url(' + this.loginBgImg + ') no-repeat center; background-size: cover;'">
          </div>
          <input class="form-control form-control-light mt-2" type="text" placeholder="输入账号" v-model="snsAccount">
          <div class="password-toggle">
            <input class="form-control form-control-light mt-2" :type="!showPwd? 'password' : 'text'"
                   id="account-password" v-model="snsPwd"
                   placeholder="输入密码" required>
            <label class="password-toggle-btn" aria-label="Show/hide password">
              <input class="password-toggle-check" type="checkbox" v-model="showPwd"><span
                class="password-toggle-indicator"></span>
            </label>
          </div>
          <div class="d-flex row mt-1 d-none">
            <el-radio-group v-model="protocol">
              <el-radio label="IPAD" border>IPAD</el-radio>
              <el-radio label="ANDROID_PAD" border>ANDROID_PAD</el-radio>
              <el-radio label="ANDROID_PHONE" border>ANDROID_PHONE</el-radio>
            </el-radio-group>
          </div>
          <div class=" d-flex  justify-content-between d-none">
            <el-checkbox v-model="checkLogin" label="自动登录" size="large" @change="handleAutoLogin()"/>
            <el-checkbox v-model="checkStart" label="自动启动" size="large" @change="handleAutoStart()"/>
          </div>
          <input v-show="isShowSlider" class="form-control form-control-light mt-2" placeholder="滑块链接" type="text"
                 v-model="slideUrl">
          <div class="form-group form-control-light mt-2" v-show="isShowSlider"
               style="padding: 0px;background-color: rgba(255,255,255,.04);border-color: rgba(255,255,255,.12)">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control form-control-light" placeholder="ticket从devTools中获取"
                     v-model="ticket">
            </div>
            <button type="button" class="btn btn-translucent-primary btn-sm" @click="submitSlider">提交TICKET</button>
          </div>
          <div class="form-group form-control-light mt-2" v-show="isShowCode"
               style="padding: 0px;background-color: rgba(255,255,255,.04);border-color: rgba(255,255,255,.12)">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control form-control-light" placeholder="短信验证码"
                     v-model="msgCode">
            </div>
            <button type="button" class="btn btn-translucent-primary btn-sm" @click="submitCode">提交验证码</button>
          </div>
        </div>
      </div>
      <div class="card-footer p-1 border-0">
        <div class="border-light text-center pt-2 pb-2">
          <!--          <button class="btn btn-primary btn-sm " style="width: 6.5rem" @click="startMQ">启动MQ服务</button>-->
          <button v-show="!powerBtnLoading" type="button" class="btn btn-outline-primary btn-sm ms-2"
                  style="width: 8rem"
                  @click="power">
            <i :class="powerBtnSts.icon + ' me-2'"></i>{{ powerBtnSts.text }}
          </button>
          <button v-show="powerBtnLoading" type="button" class="btn btn-secondary btn-sm ms-2" style="width: 8rem">
            <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
            登录中...
          </button>
          <button type="button" class="btn btn-outline-light btn-sm ms-2" style="width: 8rem"
                  data-bs-dismiss="modal" aria-label="Close">关闭
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import LogMsgHandler from "@/components/message/handlers/GlobalHandler";

export default {
  props: {
    uid: {
      type: String,
      required: true
    },
    sns: {
      type: Object,
      required: false,
    }
  },
  data: () => {
    return {
      scanStatus: 9999,
      toc: 20,
      tocTimer: -1,
      loading: false,
      snsPwd: '',
      snsAccount: '',
      loginBgImg: 'https://zhycit-static.oss-cn-beijing.aliyuncs.com/icons/tubiaopng/tongxun/Q0.png',
      isOnline: false,
      powerBtnSts: {},
      powerBtnInfo: {
        started: {icon: 'fi-power', text: '退出', btn: 'btn-primary', status: true},
        stopped: {text: '登录', icon: 'fi-play', btn: 'btn-secondary', status: false}
      },
      isPwdLogin: true,
      powerBtnLoading: false,
      showPwd: false,
      ticket: '',
      isShowSlider: false,
      isShowCode: false,
      slideUrl: '',
      msgCode: '',
      checkLogin: false,
      checkStart: false,
      protocol: 'ANDROID_PAD'
    }
  },
  watch: {
    powerBtnLoading() {
      if (this.powerBtnLoading) {
        setTimeout(() => {
          this.powerBtnLoading = false
          this.scanStatus = 'retry'
        }, this.isPwdLogin ? 90000 : 10000)
      }
    }
  },
  methods: {
    initMsgHandler(currentSnsId) {
      if ('function' === typeof window.ginkgoTo['pushMsgHandler']) {
        console.log('开启登录事件监听', currentSnsId)
        const that = this
        window.ginkgoTo['pushMsgHandler']('loginListener', msg => {
          that.snsLoginListener(msg, currentSnsId)
        })
      }
      // filePathElement.innerText = filePath
    },
    snsLoginListener(msg, currentSnsId) {
      // console.log("oicqLogin--msg--->", JSON.stringify(msg))
      try {
        const {objData, type} = msg
        if (objData) {
          const {snsId} = objData
          if (27 === type && currentSnsId === (snsId + '')) {
            this.loginBgImg = objData?.user?.payload?.avatar
            this.scanStatus = 0
            this.powerBtnSts = this.powerBtnInfo.started
            this.isOnline = true
          }
          if (0x2714 === type && currentSnsId === (snsId + '')) {
            // this.powerBtnLoading = false
            this.powerBtnSts = this.powerBtnInfo.stopped
            this.isOnline = false
            return ElMessage({
              message: "账号已退出",
              type: 'success'
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    setView(args) {
      const {open, close} = args
      console.log('openClose:', args)
      for (const e in open) {
        // console.log("open: ", open[e].switch)
        open[e].switch = true
      }
      for (const e in close) {
        // console.log("close: ", e)
        close[e].switch = false
      }
    },

    start(_this) {
      // 将用户登录过的snsAccount记录到vuex的store里，这里是全局的
      if (!this.isOnline && this.$gkt) { // 用gkt来判断是不是electron的终端
        console.log(`SettingView is setup(OL=${this.isOnline})...`, this.$store.getters.fetchSns(this.snsAccount))
        this.initMsgHandler(this.snsAccount)
        const that = _this || this
        //判断MCL程序是否启动，若启动，则直接执行登录方法。
        const snsAcc = this.snsAccount
        LogMsgHandler.currentSnsId = snsAcc
        LogMsgHandler.addMsgHandle(this.snsAccount, this, (msg) => {
          if (msg) {
            console.log('mcl>>>' + msg?.msg)
            if (msg?.msg.indexOf('https://ssl.captcha.qq.com/template/wireless_mqq_captcha.html') >= 0) {
              console.log('接收到此参数---关闭-loading')
              that.loading = false
              try {
                that.isShowSlider = true
                const startIndex = msg?.msg.toString().indexOf('https://ssl.captcha.qq.com/template/wireless_mqq_captcha.html');
                const endIndex = msg?.msg.toString().lastIndexOf('apptype=2') + 9;
                const url = msg?.msg.toString().substring(startIndex, endIndex)
                console.log('url:::', url)
                that.slideUrl = url
                return ElMessage({
                  message: '请把该链接在浏览器打开，获取ticket，然后提交',
                  type: 'warning'
                })
              } catch (e) {
                return ElMessage({
                  message: '获取ticket失败',
                  type: 'error'
                })
              }
            }
            if (msg?.msg.indexOf('一条短信验证码将发送到你的手机') >= 0) {
              that.loading = false
              try {
                window.ginkgoTo.invokeIpcMain('execMclCommand', null, 'yes')
                that.isShowCode = true
                const startIndex = msg?.msg.toString().indexOf('一条短信验证码将发送到你的手机');
                const endIndex = msg?.msg.toString().lastIndexOf('运营商可能会收取正常短信费用');
                const phone = msg?.msg.substring(startIndex, endIndex);
                console.log('phone:', phone)
                return ElMessage({
                  message: phone + ' 请注意查收',
                  type: 'success'
                })
              } catch (e) {
                return ElMessage({
                  message: '发送验证码到手机失败',
                  type: 'error'
                })
              }
            }
            if (msg?.msg.indexOf('code=163') >= 0) {
              that.loading = false
              return ElMessage({
                message: '验证码输入错误',
                type: 'error'
              })
            }
            if (msg?.msg.indexOf('code=1') >= 0) {
              that.loading = false
              return ElMessage({
                message: '登录失败,账号或密码错误',
                type: 'error'
              })
            }
            if (msg?.msg.indexOf('code=235') >= 0) {
              that.loading = false
              return ElMessage({
                message: '登录失败,密码登录被风控导致的问题',
                type: 'error'
              })
            }
            if (msg?.msg.indexOf('code=45') >= 0) {
              that.loading = false
              //删除该账号信息
              console.log('需要手动删除该账号下的设备信息')
              // window.ginkgoTo.deleteDeviceInfo(this.snsAccount)
              return ElMessage({
                message: '登录失败，该账号已被风控',
                type: 'error'
              })
            }
            // (2551688459) Login successful  | Bot login successful | Bot.2707302387: Bot login successful.
            const _msg = msg?.msg
            /*const loginSns = '(' + that.snsAccount + ') Login successful'
            console.log('loginSns--->', loginSns)
            if (_msg.indexOf(loginSns) > 0) {
              console.log('login---successful')
            }*/
            if (_msg && _msg.indexOf('login successful') > 0 && (_msg.startsWith('Bot') || 0 < _msg.indexOf(that.snsAccount))) {
              that.loading = false
              window.ginkgoTo.invoke('executeSnsApi', {
                uid: that.uid,
                method: 'login',
                snsType: 'MQ',
                snsId: that.snsAccount,
                args: {
                  snsId: that.snsAccount,
                  snsPwd: that.snsPwd,
                  wsUrl: 'ws://localhost:52099/all?verifyKey=' + window.ginkgoTo.VerifyKey + '&qq=' + that.snsAccount,
                  verifyKey: window.ginkgoTo.VerifyKey
                }
              })
            }
            if (msg?.msg.indexOf('mirai-console stopped successfully') >= 0) {
              that.loading = false
              return ElMessage({
                message: '程序启动失败,请重新启动',
                type: 'warning'
              })
            }
            if (msg?.msg.indexOf('MQ LOGOUT') >= 0) {
              that.loading = false
              return ElMessage({
                message: '程序启动失败,请重新启动',
                type: 'warning'
              })
            }
            if (msg?.msg.indexOf('Address already in use') >= 0) {
              that.loading = false
              window.ginkgoTo.invokeIpcMain('execMclCommand', null, 'stop')
              return ElMessage({
                message: '程序启动失败,请重新启动',
                type: 'error'
              })
            }
            if (msg?.msg.indexOf('Bot cancelled: Bot closed') >= 0) {
              that.loading = false
              // window.ginkgoTo.invokeIpcMain('execMclCommand', null, 'stop')
            }
            if (msg?.msg.indexOf('mcl-is-ok') >= 0) {
              console.log('------Mq --- OK ---')
              that.loginMCl()
            }
            if (msg?.msg.indexOf('已登出') >= 0) {
              that.loading = false
              that.isOnline = false
            }
          }
        })
        window.ginkgoTo.mclProgramRuns().then(res => {
          console.log('mclProgramRuns res, ', res)
          if (res) {
            that.loginMCl()
          } else {
            that.loading = true
            window.ginkgoTo.invokeIpcMain('startMCL', (event, msg) => {
              LogMsgHandler.mclMsgHandle(msg)
            })
          }
        })
      }
    },
    stop() {
      if (this.gkt) {
        this.gktExecuteSnsApi({uid: this.uid, method: 'logout', snsType: 'OICQ', snsId: this.snsId, args: {}})
      }
    },
    power() {
      if (!this.snsAccount) {
        return ElMessage({
          message: "请输入账号...",
          type: 'warning'
        })
      }
      if (!this.snsPwd) {
        return ElMessage({
          message: "请输入密码...",
          type: 'warning'
        })
      }
      this.powerBtnLoading = true
      this.start(this)
    },
    changePage(i) {
      if (1 === i) {
        this.isPwdLogin = false;
      } else if (2 === i) {
        this.isPwdLogin = true;
      }
    },
    //提交滑动模块信息
    submitSlider() {
      console.log('ticket:', this.ticket)
      if (this.ticket) {
        window.ginkgoTo.invokeIpcMain('execMclCommand', null, this.ticket)
      } else {
        return ElMessage({
          message: '请输入获取的ticket',
          type: 'warning'
        })
      }
    },
    submitCode() {
      if (this.msgCode) {
        console.log('msgCode-->', this.msgCode)
        window.ginkgoTo.invokeIpcMain('execMclCommand', null, this.msgCode)
      } else {
        return ElMessage({
          message: '请输入短信验证码',
          type: 'warning'
        })
      }
    },
    loginMCl() {
      let args = 'login ' + this.snsAccount + ' ' + this.snsPwd + ' ' + this.protocol
      console.log('login--->', args)
      window.ginkgoTo.invokeIpcMain('execMclCommand', null, args)
    },

    handleAutoLogin() {
      if (!this.snsAccount) {
        return ElMessage({
          message: '请先输入账号',
          type: 'warning'
        })
      }
      if (!this.snsPwd) {
        return ElMessage({
          message: '请先输入密码',
          type: 'warning'
        })
      }
      console.log(this.checkLogin)
      if (this.checkLogin) {
        const configAdd = 'autoLogin add ' + this.snsAccount + ' ' + this.snsPwd;
        window.ginkgoTo.invokeIpcMain('execMclCommand', null, configAdd)
      } else {
        const configRemove = 'autoLogin remove ' + this.snsAccount;
        window.ginkgoTo.invokeIpcMain('execMclCommand', null, configRemove)
      }
      let p = this.$dba.getItem('mclConfig', {});
      let c = p[this.snsAccount]
      if (!c) {
        c = {}
      }
      c.autoLogin = this.checkLogin
      p[this.snsAccount] = {...c}
      this.$dba.setItem('mclConfig', p)
    },
    //自动启动
    handleAutoStart() {
      let p = this.$dba.getItem('mclConfig', {});
      let c = p[this.snsAccount]
      if (!c) {
        c = {}
      }
      c.autoStart = this.checkStart
      p[this.snsAccount] = {...c}
      this.$dba.setItem('mclConfig', p)
    }

  },
  setup() {
    // setup
    // 1. 检查是否是electron的应用程序，如果是，window里面会有ginkgoTo对象
    const gkt = window.ginkgoTo

    const store = useStore()

    /**
     * 调用SNS相关接口
     *
     * params = {
     *       uid: 'xxxxxxx', // 当前登录系统的用户id，原BIS或是YLL的用户
     *       method: 'login',    // SNS对应的方法名（参考puppet框架）
     *       snsType: 'OICQ',    // SNS的类型，参考MsgType
     *       snsId: '2551688459', // QQ号、微信号、公众号或是小程序等
     *       args: {}     // PUPPET方法需要的参数
     *     }
     * */
    function gktExecuteSnsApi(params) {
      const EXE_SNS_API = 'executeSnsApi'
      console.log('gktExecuteSnsApi:', params)
      if ('function' === typeof gkt['invoke']) {
        // window.ginkgoTo.invoke('executeSnsApi', {uid: 'xxxxxxx', method: 'login', snsType: 'OICQ', snsId: '2551688459', args: {}})
        gkt['invoke'](EXE_SNS_API, params)
      }
    }

    // console.log('SettingView is setup...', store.getters.snsList)
    return {
      gkt,
      store,
      gktExecuteSnsApi
    }
  },
  created() {
    this.powerBtnSts = this.powerBtnInfo.stopped
    if (this.sns) {
      console.log('sns===000>>', this.sns)
      this.snsAccount = this.sns.snsId
      this.snsPwd = this.sns.snsPwd || this.sns.payload.pwd
      this.loginBgImg = this.sns.avatar
    }
  }
}
</script>

<style scoped>
.ginkgoto-sns-bind {
  width: 23rem;
  height: 30rem;
}
</style>
